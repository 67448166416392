import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

 arrayvalues = [];
 showAddDomain = false;
 showLisDomain = false;
 showPendDomain = false; 
 showAllDomain = false;
 showHideDomain = false;
 AllSelectedId=[];
 specificJob = true;  
 specificJob1 = true;
 AllSelectedId1 = [];
 showeditDomain = false;
 model = {};
 tableList1 = []; //declare an empty array table 1 
 tableList2 = [];  
 showSkills = [];
 levelsofarray = [];
 skills = [];
 tableList10 = []; //declare an empty array table 2
 Expand2 = false;
 pages = [];
 page = [];
 currentPage = 1;	numPerPage = 5;
 maxSize = 5;
 count = "";
 showPendSkills = false; 
 showAddedSkills = false; 
 showNotReviewedSkills = false;   
 showHideSkills = false;
 showListSkills = false;
 showmapSkills = false;
 showallskills = false;  
 selectThisJob1 = false; 
 showhidedskills = false; 
 showhidedapproveskills = false;
 searchcategory = [];
 //baseUrl = UserService.url;
 flagexist = false;
 filteredArray = [];
 arrayvalueid = [];
 _firstSkillIndex = 0;
 _lastSkillIndex = 2;
 isLeftSkillActive = false;
 isRightSkillActive =false;     
 filteredIndustry = [];
 _firstIndustryIndex = 0;
 _lastIndustryIndex = 2;
 isLeftIndustryActive = false;
 isRightIndustryActive =false; 
 rolesAdded = false;
 tooltip = false; 
 roleValue = "";
 rolesAdded1 = false;
 tooltip1 = false; 
 roleValue1 = "";      
 test = false; 
  constructor() { }

  ngOnInit() {
  }

 industryJson = {
    'json': {
        'request': {
            'servicetype': '21',
            'functiontype': '1014',
           
            'industry': '_ALL_'
        }
    }
};
 categoryJson = {
    'json': {
        'request': {
            'servicetype': '21',
            'functiontype': '1014',
            'category': '_ALL_'
        }
    }
};

 subcategoryJson = {
    'json': {
        'request': {
            'servicetype': '21',
            'functiontype': '1014',
            'subcategory': '_ALL_',
        }
    }
};

}
