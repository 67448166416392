import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private isUserLoggedIn;
  private username; 
  private locationurl = window.location.hostname;

  // url = "https://" + this.locationurl + ":8443"
  // url = "http://restapi-qa.theshortlistd.com:8080"
  // url = "http://52.221.14.247:8080"
  // url = "http://13.215.54.38:8082"
  url = "https://restapi.theshortlistd.com"

  constructor(private http:HttpClient,private router:Router) {
    this.isUserLoggedIn = false;
    localStorage.setItem("url",this.url);
   }

   setUserLoggedIn(){
     this.isUserLoggedIn = true;
   }

   getUserLoggedIn(){
     return this.isUserLoggedIn;
   }
}
