import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';
import { Command, Session } from 'protractor';

// import { MatDialog } from '@angular/material';


@Component({
    selector: 'app-assessors',
    templateUrl: './assessors.component.html',
    styleUrls: ['./assessors.component.css'],
    providers: [DatePipe]
})
export class AssessorsComponent implements OnInit {
    showMessage;
    tab = 1;
    assessorList = [];
    employeelist1 = [];
    skills = [];
    skillList = [];
    id = "";
    skillId = "";
    industries = "";
    assessorId = "";
    specificJob = true;
    AllSelectedId = [];
    showLoader = false;
    showAssessorsPage = false;
    showSkillsPage = false;
    addedComment = "";
    assId = localStorage.getItem('assId');
    currentAssessorId = "";
    currentPage: number = 1;
    currentTab: number = 1;
    itemsPerPage: number = 10;
    totalItems: number = 0;
    url = localStorage.getItem("url");
    selectedAssessor: string = 'Freelancer';
    assessorSkillList: { skill: any[] } = { skill: [] };
    assessorID = "";
    disabledSkills: any[] = [];


    // assessorSkillList: AssessorSkill[] = [];
    constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService,) { }

    ngOnInit() {
        this.listingAssessors();

    }

    decodeString(str) {
        if (str != undefined) {
            return (unescape(decodeURIComponent(str)));
        }
    }

    convertDate(date) {
        return new Date(date);
    };

    searchSkill;
    referredBy;
    name;
    search;
    companyName;
    pagination
    trimmedSearchName;
    trimmedSearch;
    trimmedSearchCompanyName;

    assessPagination(tab) {
        if (tab == 1) {
            this.listingAssessors()
        } else if (tab == 2) {
            this.listingApprovedAssessors()
        } else {
            this.listingDisabledAssessors()
        }
    }

    onAssessorChange() {
        // Function triggered on dropdown selection change
        if (this.selectedAssessor === 'Corporate') {
            // If Corporate Assessor is selected, set the active tab to Approved (tab = 2)
            this.tab = 2;
        } else {
            // For other assessors, set the active tab to UnApproved (tab = 1)
            this.tab = 1;
        }
    }

    listingAssessors() {
        this.tab = 1;

        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }

        if (this.name !== undefined) {
            this.trimmedSearchName = this.name.trim();
        }

        if (this.search !== undefined) {
            this.trimmedSearch = this.search.trim();
        }

        if (this.companyName !== undefined) {
            this.trimmedSearchCompanyName = this.companyName.trim();
        }

        this.showLoader = true;
        // this.showAssessorsPage = true;
        // this.showSkillsPage = false;
        ;
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    }, "records": {
                        "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "referredBy": this.referredBy,
                        "name": this.trimmedSearchName,
                        "approved": 0,
                        "emailOrMobile": this.trimmedSearch,
                        /*"skill":$scope.model.searchSkill,*/
                        "companyName": this.trimmedSearchCompanyName,
                        // "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showMessage = false;

                        this.searchApprovedName = "";
                        this.searchApprovedEmainOrMobile = "";
                        this.searchApprovedCompanyName = "";

                        this.searchDisabledName = "";
                        this.searchDisabledEmainOrMobile = "";
                        this.searchDisabledCompanyName = "";

                        this.showLoader = false;
                        if (res.json.response.data.assessorList.length === 0) {
                            this.showMessage = true;
                        }
                        this.assessorList = res.json.response.data.assessorList;
                        this.totalItems = res.json.response.data.count;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    }

                });

    };
    skills1 = [];
    showEnable = false;
    editComment() {
        this.showEnable = true;
    }
    data; availabilityTime; from; to; errorMessage;
    viewDetails(item) {
        this.skills1 = [];
        this.skills = [];

        // if (item == undefined) {
        this.assId = item
            // }
            ;

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2044",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "assessorId": this.assId
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        //$('#viewJobModal').modal('show');
                        this.data = res.json.response.data;
                        this.availabilityTime = res.json.response.data.settings;
                        this.availabilityTime = res.json.response.data.settings;
                        this.from = this.availabilityTime[0].from;
                        this.to = this.availabilityTime[0].to;
                        this.skills = this.data.skill;
                        for (var j = 0; j < this.skills.length; j++) {
                            this.skills1.push(this.skills[j].skillName);
                        }
                        this.display = 'block';
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }

    display;
    OnCloseHandled() {
        this.display = 'none';
    }

    newDate; date;
    viewByRefCode(item) {
        ;

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2045",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "referenceCode": item
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        //$('#viewRefModal').modal('show');
                        this.data = res.json.response.data;

                        for (var i = 0; i < this.data.length; i++) {
                            this.newDate = new Date(this.data[i].createdTime);
                            //this.date = $filter('date')(this.newDate, "dd MMM yyyy");
                        }
                        this.skills = this.data.skill;
                        for (var j = 0; j < this.skills.length; j++) {
                            this.skills1.push(this.skills[j].skillName);
                        }
                    } else if (res.json.response.statuscode == 50097) {


                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }

    // decode(str){
    //     return unescape(str.replace(/[+]/g, "%20"))
    //   }

    modelValue = "";
    count;
    searchApprovedName;
    searchApprovedEmainOrMobile;
    searchApprovedCompanyName;
    trimmedSearchApprovedName;
    trimmedSearchApproved;
    trimmedSearchApprovedCompanyName;
    listingApprovedAssessors() {
        this.tab = 2;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.searchApprovedName !== undefined) {
            this.trimmedSearchApprovedName = this.searchApprovedName.trim();
        }

        if (this.searchApprovedEmainOrMobile !== undefined) {
            this.trimmedSearchApproved = this.searchApprovedEmainOrMobile.trim();
        }

        if (this.searchApprovedCompanyName !== undefined) {
            this.trimmedSearchApprovedCompanyName = this.searchApprovedCompanyName.trim();
        }

        this.modelValue = status;
        ;
        // this.showAssessorsPage = true;
        // this.showSkillsPage = false;
        this.assessorList = [];
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records": {
                        "offset": (this.currentPage - 1) * this.itemsPerPage,
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "referredBy": this.referredBy,
                        "name": this.trimmedSearchApprovedName,
                        "approved": 1,
                        "emailOrMobile": this.trimmedSearchApproved,
                        "skill": skill,
                        "companyName": this.trimmedSearchApprovedCompanyName,
                        // "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*     this.showLoader = false;  */
                    }

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showMessage = false;
                        if (res.json.response.data.assessorList.length === 0) {
                            this.showMessage = true;
                        }

                        this.name = "";
                        this.search = "";
                        this.companyName = "";

                        this.searchDisabledName = "";
                        this.searchDisabledEmainOrMobile = "";
                        this.searchDisabledCompanyName = "";

                        /* this.showLoader = false;*/
                        this.assessorList = res.json.response.data.assessorList;
                        this.count = res.json.response.data.count;
                        this.totalItems = res.json.response.data.count
                        // this.tab = 1;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    searchDisabledName;
    searchDisabledEmainOrMobile;
    searchDisabledCompanyName;
    trimmedSearchDisabledName;
    trimmedSearchDisabled;
    trimmedSearchDisabledCompanyName
    listingDisabledAssessors() {
        this.tab = 3;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.searchDisabledName !== undefined) {
            this.trimmedSearchDisabledName = this.searchDisabledName.trim();
        }

        if (this.searchDisabledEmainOrMobile !== undefined) {
            this.trimmedSearchDisabled = this.searchDisabledEmainOrMobile.trim();
        }

        if (this.searchDisabledCompanyName !== undefined) {
            this.trimmedSearchDisabledCompanyName = this.searchDisabledCompanyName.trim();
        }

        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records": {
                        "offset": (this.currentPage - 1) * this.itemsPerPage,
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "referredBy": this.referredBy,
                        "name": this.trimmedSearchDisabledName,
                        "emailOrMobile": this.trimmedSearchDisabled,
                        "skill": skill,
                        "companyName": this.trimmedSearchDisabledCompanyName,
                        "approved": -1,
                        // "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /* this.showLoader = false; */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showMessage = false;
                        if (res.json.response.data.assessorList.length === 0) {
                            this.showMessage = true;
                        }

                        this.name = "";
                        this.search = "";
                        this.companyName = "";

                        this.searchApprovedName = "";
                        this.searchApprovedEmainOrMobile = "";
                        this.searchApprovedCompanyName = "";

                        /*   this.showLoader = false;*/
                        this.assessorList = res.json.response.data.assessorList;
                        this.totalItems = res.json.response.data.count
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingSponsoredAssessorsDisable() {
        /*  this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": true,
                        "approved": -1,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*     this.showLoader = false; */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /*this.showLoader = false;*/
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingFreelanceAssessorsDisable() {
        /*this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": false,
                        "approved": -1,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*  this.showLoader = false;  */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /*this.showLoader = false; */
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingSponsoredAssessorsUnapprove() {
        /*this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": true,
                        "approved": 0,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*this.showLoader = false; */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /*this.showLoader = false; */
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingFreelanceAssessorsUnapprove() {
        /*this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": false,
                        "approved": 0,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /* this.showLoader = false;  */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /* this.showLoader = false; */
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingSponsoredAssessorsApprove() {
        /* this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": true,
                        "approved": 1,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*this.showLoader = false;*/
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /*this.showLoader = false; */
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingFreelanceAssessorsApprove() {
        /*this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": false,
                        "approved": 1,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /* this.showLoader = false; */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /* this.showLoader = false;*/
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingSponsoredAssessorsPending() {
        /*this.showLoader = true;*/
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": true,
                        "approved": 2,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        /*   this.showLoader = false; */
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        /*    this.showLoader = false;*/
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingFreelanceAssessorsPending() {
        this.showLoader = true;
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        "sponsored": false,
                        "approved": 2,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    approve;
    commonSponsoredAssessors() {
        ;
        if (this.approve == -1) {
            this.listingSponsoredAssessorsDisable();
        } else if (this.approve == 0) {
            this.listingSponsoredAssessorsUnapprove();
        } else if (this.approve == 1) {
            this.listingSponsoredAssessorsApprove();
        } else if (this.approve == 2) {
            this.listingSponsoredAssessorsPending();
        }
    }
    commonFreelanceAssessors() {
        if (this.approve == -1) {
            this.listingFreelanceAssessorsDisable();
        } else if (this.approve == 0) {
            this.listingFreelanceAssessorsUnapprove();
        } else if (this.approve == 1) {
            this.listingFreelanceAssessorsApprove();
        } else if (this.approve == 2) {
            this.listingFreelanceAssessorsPending();
        }
    }

    backValue = "";
    listingPendingAssessors(status) {
        this.tab = 4;
        this.showLoader = true;
        // this.showAssessorsPage = true;
        // this.showSkillsPage = false;
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "referredBy": this.referredBy,
                        "name": this.name,
                        "approved": 2,
                        "emailOrMobile": this.search,
                        "skill": skill,
                        "companyName": this.companyName,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.assessorList = res.json.response.data.assessorList;
                        this.approve = 2;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    listingEnhanceAssessors() {
        this.showLoader = true;
        this.tab = 5;
        ;
        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.assessorList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2041",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "status": "Requested"
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == "20021") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.assessorList = res.json.response.data.encashHistory;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    enableAssessors(assessorId, btnType) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2027",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "assessorId": assessorId,
                        "approved": btnType,
                        "offset": this.pagination
                    }
                }
            }
        }
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Sucess != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            if (this.tab === 1) {
                                this.listingAssessors();
                            } else if (this.tab === 2) {
                                this.listingApprovedAssessors();
                            } else if (this.tab === 3) {
                                this.listingDisabledAssessors();
                            }
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [btnType === true ? "Approved successfully" : "Unapproved sucessfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: btnType === true ? 'success' : 'danger'
                            });
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                }
            )
    };

    // display: any = 'none'; // to control the display of the modal

    assessorSkills(item) {
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2030",
                    "session": {
                        "sessionid": localStorage.getItem("sessionId")
                    },
                    "data": {
                        "assessorId": item,
                        "status": "approved"
                    }
                }
            }
        }

        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.assessorSkillList = res.json.response.data;
                        this.assessorID = res.json.response.data.assessorId;
                        this.display = 'block';

                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                        const flashMessageElement = document.querySelector('.flash-message');
                        if (flashMessageElement) {
                            document.body.appendChild(flashMessageElement); // Move the element to the end of the body
                        }
                    }
                });

    }



    disableAsseossorSkill(i: number, id: number) {
        console.log(i, id)
        if (this.assessorSkillList && Array.isArray(this.assessorSkillList.skill)) {
            if (i >= 0 && i < this.assessorSkillList.skill.length) {
                const removeSkill = this.assessorSkillList.skill.splice(i, 1);
                console.log('Skill deleted successfully.');
                this.disableAsseossorSkillApi(id)

            }
        }
    }

    disableAsseossorSkillApi(id) {

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2031",
                    "session": {
                        "sessionid": localStorage.getItem("sessionId")
                    },
                    "data": {
                        "assessorId": this.assessorID,
                        "skillId": id.id,
                        "disabled": true
                    }
                }
            }
        }

        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["disabled successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'success'
                        });
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }
}

//     OnCloseHandled() {
//         this.display = 'none'; // Hide the modal when closed
//       }

function disableAsseossorSkill() {
    throw new Error('Function not implemented.');
}

