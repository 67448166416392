import { Component, OnInit } from '@angular/core';
import { FormControl,Validators,FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  form: FormGroup;
  isIndustryDisabled: boolean = false;
  isCategoryDisabled: boolean = false;
  isSelectIndustryDisabled: boolean = false;
  isSelectCategoryDisabled: boolean = false;
  isButtonDisabled: boolean = true;

  url = localStorage.getItem("url");

  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService,) { }
  myControl = new FormControl('');
  myControlCat = new FormControl('')
  industryList: Array<any> = [];
  filteredOptions: Observable<any[]>;
  filteredOptionsCat: Observable<any[]>
  ngOnInit() {
    this.listingSkills()
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this.listingIndustry(value || '')),
    // );
    this.filteredOptions = of([]);
    this.myControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => {
          if(value == ""){
            this.isIndustryDisabled = false
          }else{
            this.isIndustryDisabled = true
          }
          return (this.listingIndustry(value));
      })
    ).subscribe(filteredData => {
      this.filteredOptions = of(filteredData);
    });

    // this.filteredOptionsCat = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filterCat(value || '')),
    // );
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.industryList.filter(item => item.industry.toLowerCase().includes(filterValue));
  // }

  _filterCat(value: string): any[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue,"filtered Value");
    return this.categoryList.filter(item => item.category.toLowerCase().includes(filterValue));
  }

  showLoader = false;
  searchIndustry;
  trimmedSearchIndustry;
  searchCategory;
  trimmedSearchCategory;
  searchSkills;
  trimmedSearchSkills
  display;
  selectIndustry;
  selectIndustryInput
  selectCategory;
  selectCategoryInput
  IndustryInput;
  CategeoryInput;
  SkillInput;
  softSkillInput;
  json;
  skillType: boolean = false;
  skillAdded;
  selectedSkill = true;
  skillList = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  invalidSession;
  errorMessage;
  isDisable = false;

  search() {
    this.currentPage = 1;
    this.listingSkills();
  }

  onChangeSkillType(type) {
    if (type) {
      this.skillType = false;
      this.selectedSkill = true;
    } else {
      this.skillType = true;
      this.selectedSkill = false;
    }
  }

  listingIndustry(value: string): Observable<any[]> {

    this.json = {
      "json": {
        "request": {
          "servicetype": "21",
          "functiontype": "1040",
          "industry": "_ALL_"
        }
      }
    };

    let temp = encodeURIComponent(JSON.stringify(this.json));

    return this.http.get(this.url + '/JobzTop/QuestionBank?request=' + temp)
      .pipe(
        map((res: any) => {

          this.industryList = res.json.response.data;
          const filterValue = value.toLowerCase();
          return this.industryList.filter(item => item.industry.toLowerCase().includes(filterValue));
        })
      )
  }
  categoryList: Array<any> = [];

  listingCategory(industry: string) {
    this.selectIndustry = industry

    this.json = {
      "json": {
        "request": {
          "servicetype": "21",
          "functiontype": "1040",
          "industry": industry
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(this.json))

    this.http.get(this.url + '/JobzTop/QuestionBank?request=' + temp)
      .subscribe(
        (res: any) => {
          this.categoryList = res.json.response.data;

           this.filteredOptionsCat = this.myControlCat.valueChanges.pipe(
            startWith(''),
            map(value => this._filterCat(value || '')),
        );
      });
  }

  onSelectCat(val: any) {
    this.selectCategory = val
    console.log(val,"category from options");
    if (val == undefined) {
      this.isCategoryDisabled = false
    }else
    this.isCategoryDisabled = true
  }

  // disableCategory() {
  //   if (this.selectCategory == "") {
  //     this.isCategoryDisabled = false;
  //   } else {
  //     this.isCategoryDisabled = true

  //   }
  // }

  checkButtonState() {
    if (this.myControl.value&& this.SkillInput ||
      this.selectIndustryInput && this.selectCategoryInput && this.SkillInput ||
      this.softSkillInput) {
      // If all conditions are met, enable the button.
      this.isButtonDisabled = false;
    } else {
      // If any of the conditions is not met, disable the button.
      this.isButtonDisabled = true;
    }
  }

  // onInputChange() {
  //   if (this.selectIndustryInput !== "" ) {
  //     this.myControl.disable();
  //     this.myControlCat.disable();
  //     this.isSelectCategoryDisabled = true

  //   } else {
  //     // this.isIndustryDisabled = false; // Enable the input field
  //     this.myControl.enable(); // Enable the select
  //     this.myControlCat.enable();
  //     this.isSelectCategoryDisabled = false;
  //     this.isCategoryDisabled = false

  //   }
  // }
  
// Latest New Category changes
  onInputChange() {
    if (this.myControl.value !== '' && this.selectCategoryInput !== '') {
      this.myControlCat.disable();
    } else if (this.selectIndustryInput !== '') {
      this.myControl.disable();
      this.myControlCat.disable();
    } else {
      this.myControl.enable();
      this.myControlCat.enable();
      this.isSelectCategoryDisabled = false;
      this.isCategoryDisabled = false;
    }
  }

  listingSkills() {

    this.showLoader = true;

    if (this.searchIndustry !== undefined) {
      this.trimmedSearchIndustry = this.searchIndustry.trim();
    }

    if (this.searchCategory !== undefined) {
      this.trimmedSearchCategory = this.searchCategory.trim();
    }

    if (this.searchSkills !== undefined) {
      this.trimmedSearchSkills = this.searchSkills.trim();
    }

    this.json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2075",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
   				 "sortby": "s.LastUpdatedTime",
   				 "orderby": "desc"
     		 },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },

          "data": {
            "industry": this.searchIndustry,
            "category": this.searchCategory,
            "skill": this.searchSkills
          }

        }
      }
    };

    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(this.json)))
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          } if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.totalItems = res.json.response.count;
            this.skillList = res.json.response.data;

            // }
            // if (res.json.response.data.skillList.length > 0) {
            //   this.showLoader = false;
            //   // this.totalItems = res.json.response.count; 
            //   this.skillList = res.json.response.data.skillList;
          } else if (res.json.response.statuscode == "50097") {
            this.invalidSession(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
          // else {
          //   this.totalItems = res.json.response.count;
          //   this.skillList = res.json.response.data;
          //   this.showLoader = false;
          // }
        });
  }
  addPopup() {
    this.display = 'block';
    this.selectIndustry = "";
    this.selectCategory = "";
    this.IndustryInput = "";
    this.CategeoryInput = "";
    this.SkillInput = "";
    this.softSkillInput = "";
    this.isCategoryDisabled = false;
    this.isIndustryDisabled = false;
    this.industryList = [];
    this.selectIndustryInput = ""
    this.myControl.enable();
    this.myControlCat.enable();
  }

  addSkill() {
    this.json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2074",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "industry": this.skillType == true ? (undefined) : (this.selectIndustry || this.selectIndustryInput),
            "category": this.skillType == true ? (undefined) : (this.selectCategory || this.selectCategoryInput),
            "skill": this.SkillInput || this.softSkillInput,
            "isSoftSkill": this.skillType
          }
        }
      }
    }
    let temp = encodeURIComponent(JSON.stringify(this.json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20051") {
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Added successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            this.myControl.setValue('');
            this.skillAdded = res.json.response.data
            this.display = 'none';
            this.listingSkills()
            this.selectCategoryInput=""
            this.myControl.enable();
            this.myControlCat.enable();
            this.myControlCat.setValue('');
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage = res.json.response.statusmessage;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  OnCloseHandled() {
    this.display = 'none';
    this.selectIndustry = "";
    this.selectIndustryInput = ""
    this.selectCategoryInput=""
    this.selectCategory = "";
    this.IndustryInput = "";
    this.CategeoryInput = "";
    this.SkillInput = "";
    this.softSkillInput = "";
    this.isCategoryDisabled = false;
    this.isIndustryDisabled = false;
    this.isSelectIndustryDisabled = false;
    this.isSelectCategoryDisabled = false;
    this.isButtonDisabled = true;
    this.myControl.setValue('');
    this.myControlCat.setValue('');
    this.myControl.enable();
    this.myControlCat.enable();
  }
}
