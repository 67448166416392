import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { UserService } from '../user.service';

@Component({
  selector: 'app-add-panel',
  templateUrl: './add-panel.component.html',
  styleUrls: ['./add-panel.component.css']
})
export class AddPanelComponent implements OnInit {
  showLoader = false;
  listPanel = [];
  companieslist = [];
  companNameId = [];
  companNames = [];
  assessorIdsNamesArray = [];
  assessorIdsArray = [];
  assessorList = [];
  errorMessage;
  url = localStorage.getItem("url");

  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private user: UserService) { }

  ngOnInit() {
    this.listingPanel();
    this.listingCompanies();
    this.skillsforJobs();
    this.skillIdsNames("");
  }

  currentPage:number=1;
  currentTab:number=1
  itemsPerPage:number=10;
  totalItems:number=0;

  search() {
    // this.currentPage = 1;
    // this.listingPanel()
  }

  searchPanelName;
  trimmedSearchPanelName;
  addPagination(tab){
    if(this.tab == 1){
      this.listingPanel()
    }else{
      this.listingDisabledPanel()
    }
  }
  listingPanel() {
    this.showLoader = true;
    ;
    this.tab = 1;

    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }
    if (this.searchPanelName !== undefined) {
      this.trimmedSearchPanelName = this.searchPanelName.trim();
    }

    this.listPanel = [];

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2070",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "disabled": false,
            "name": this.trimmedSearchPanelName
          }
        }
      }
    };

    let temp = encodeURIComponent(JSON.stringify(json))
    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          (JSON.stringify(res));
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            // this.currentPage = 1;
            this.DisabledSearchPanelName = ""
            this.showLoader = false;
            this.listPanel = res.json.response.data.panelList;
            this.totalItems =res.json.response.data.count;
          console.log(res.json.response.data.count);
          
            (this.listPanel);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  DisabledSearchPanelName;
  trimmedDisabledSearchPanelName;
  listingDisabledPanel() {
    this.tab = 2;
    this.showLoader = true;
    if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
    if (this.DisabledSearchPanelName !== undefined) {
      this.trimmedDisabledSearchPanelName = this.DisabledSearchPanelName.trim();
    }

    this.listPanel = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2070",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "disabled": true,
            "name": this.trimmedDisabledSearchPanelName
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.currentPage = 1;
            this.searchPanelName = ""
            this.showLoader = false;
            this.listPanel = res.json.response.data.panelList;
            this.totalItems =res.json.response.data.count
            (this.listPanel);
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  listComp = [];
  searchCompanyName; searchDomain; searchCredits;
  companiesList; count;
  listingCompanies() {
    this.showLoader = true;
    ;
    this.companieslist = [];
    this.listComp = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2049",
          "sort": {
            "sortby": "createdtime",
            "orderby": "DESC"
          },
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "companyName": this.searchCompanyName,
            "domain": this.searchDomain,
            "totalcredits": this.searchCredits
          }
        }
      }
    };

    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.companiesList = res.json.response.data.companyList;
            (this.companiesList);
            this.count = res.json.response.data.count;
            for (var i = 0; i < this.companiesList.length; i++) {
              this.listComp.push(this.companiesList[i]);
              (this.listComp);
            }
            (this.companiesList);
            (this.listComp);
            (this.listComp.length);
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  display;
  selectSkillName1;
  selectAsessorName1;
  selectCompanyName1;
  addPopup() {
    this.display = 'block';
    this.companieslist = [];
    this.companNameId = [];
    this.companNames = [];
    this.assessorIdsNamesArray = [];
    this.assessorIdsArray = [];
    this.assessorList = [];
    this.name = "";
    this.selectSkillName1 = ""
    this.selectAsessorName1 = ""
    this.selectCompanyName1 = ""
  }

  name;
  addPanel() {
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2069",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "name": this.name,
            "assessorId": this.assessorIdsArray,
            "companyId": this.companNameId
          }
        }
      }
    }
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Added successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.assessorIdsNamesArray = [];
              this.assessorIdsArray = [];
              this.companNameId = [];
              this.companNames = [];
              this.display = 'none';
              this.listingPanel();
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  }

  OnCloseHandled() {
    this.display = 'none';
  }

  panelId = "";
  displayEdit;
  editPanel(item) {
    (item);
    this.panelId = item.panelId;
    this.name = item.name;
    (this.name);
    (this.panelId);
    this.displayEdit = 'block';
  }

  update() {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2071",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "panelId": this.panelId,
            "name": this.name
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.json.response.statuscode == 0) {
            (JSON.stringify(res));
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Added successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Updated successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            this.displayEdit = 'none';
            this.listingPanel();
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  OnClose() {
    this.displayEdit = 'none';
  }

  assignId = "";
  disablePanel(PanelId) {
    ;
    ;
    (PanelId.panelId);
    this.assignId = PanelId.panelId;
    (this.assignId);
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2071",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": true,
            "panelId": this.assignId,
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingPanel();
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Disabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  id = "";
  enablePanel(id) {
    ;
    this.id = id.panelId;
    (id);
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2071",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": false,
            "panelId": this.id
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          (JSON.stringify(json));
          (JSON.stringify(res));
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingPanel();
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Enabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  convertDateconvertDate(date) {
    return new Date(date);
    (date);
  };

  skills = [];
  skillsforJobs() {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "5",
          "functiontype": "5005"
        },
        "session": {
          "sessionid": localStorage.getItem('sessionId')
        }
      }
    };
    // let temp = encodeURIComponent(JSON.stringify(json))

    (JSON.stringify(json));
    this.http.post(this.url + '/JobzTop/Job?request=', JSON.stringify(json))
      .subscribe(
        (res: any) => {
          (JSON.stringify(res));
          if (res.success !== undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.showLoader = false;
              this.skills = res.json.response.data;
              (this.skills, "skills");
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }

        });
  };

  skillIdsNames(status) {
    this.assessorList = [];
    this.companieslist = [];
    this.companNameId = [];
    this.companNames = [];
    this.assessorIdsNamesArray = [];
    this.assessorIdsArray = [];
    this.assessorList = [];
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2026",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdtime",
            "orderby": "DESC"
          },
          "data": {

            "approved": 1,
            "skill": status,
            "sponsored": false
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json));

    (JSON.stringify(json));
    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          (JSON.stringify(res));
          if (res.json.response.statusmessage == "No match found") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.assessorList = res.json.response.data.assessorList;
            (res.json.response.data.assessorList);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: ["No Assessors Found 'Please Choose Other Skills'"],
              // messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  tab;
  commonSearch() {
    if (this.tab == 1) {
      this.listingPanel();
    } else if (this.tab == 2) {
      this.listingDisabledPanel();
    }
  }

  assessorIdsNames(data) {
    ;
    (data);
    for (var i = 0; i < this.assessorList.length; i++) {
      if (data == this.assessorList[i].assessorId)
        if (this.assessorIdsNamesArray.indexOf(this.assessorList[i].name) == -1) {
          this.assessorIdsNamesArray.push(this.assessorList[i].name)
          this.assessorIdsArray.push(this.assessorList[i].assessorId)
        }
    }
    (this.assessorIdsNamesArray);
    (this.assessorIdsArray);
  }

  companyNames(data) {
    for (var i = 0; i < this.companiesList.length; i++) {
      if (data == this.companiesList[i].companyId) {
        if (this.companNames.indexOf(this.companiesList[i].companyName) == -1) {
          this.companNames.push(this.companiesList[i].companyName);
          this.companNameId.push(this.companiesList[i].companyId);
        }
      }
    }
    (this.companNameId);
  }

  deleteCompany(data) {
    for (var k = 0; k < this.companNames.length; k++) {
      if (data == this.companNames[k]) {
        var idx = this.companNames.indexOf(data);
        this.companNames.splice(idx, 1);
        this.companNameId.splice(idx, 1);
      }
    }
    (this.companNames);
    (this.companNameId);
  }

  deleteAssessor(data) {
    for (var k = 0; k < this.assessorIdsNamesArray.length; k++) {
      if (data == this.assessorIdsNamesArray[k]) {
        var idx = this.assessorIdsNamesArray.indexOf(data);
        this.assessorIdsNamesArray.splice(idx, 1);
        this.assessorIdsArray.splice(idx, 1);
      }
    }
    (this.companNames);
    (this.companNameId);
  }

  decode(str) {
    return unescape(str.replace(/[+]/g, "%20"))
  }

}
