import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]
})

export class DashboardComponent implements OnInit {

  listOfData: any;
  listOfData1: any;
  public pieChartLabels: string[] = ['Candidates', 'Employers', 'Assessors', 'Jobs', 'Companies'];
  public pieChartData: number[] = [0, 0, 0, 0, 0];
  public pieChartData1: number[] = [0, 0, 0, 0, 0];
  public pieChartType: string = 'pie';
  interviewDate: any;
  interviewDate1: any;
  showLoader = false;
  errorMessage = false;
  url = localStorage.getItem("url");
  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService) { }

  ngOnInit() {
    ;
    this.dashboard();
    this.interviewDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.interviewDate1 = this.datePipe.transform(new Date(), 'dd-MMM-yyyy');
    this.listingSchedules();
  }

  dashboard() {
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2056",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          }
        }
      }
    }
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == "0") {
            this.listOfData = res.json.response.data;
            this.pieChartData1 = [this.listOfData.candidateCount, this.listOfData.emloyerCount, this.listOfData.assessorCount, this.listOfData.jobCount, this.listOfData.companyCount];
            this.pieChartType = 'pie';
          } else if (res.json.response.statuscode == 50097) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  listSchedules = [];
  json1: any;
  showMessage;
  listingSchedules() {
    this.showLoader = true;
    this.listOfData1 = [];
    this.errorMessage = false;
    ;
    if (this.interviewDate1 == "" || this.interviewDate1 == undefined || this.interviewDate1 == null) {
      this.json1 = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2052",
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "data": {
              "startDate": this.interviewDate,
              "endDate": this.interviewDate
            }
          }
        }
      }
    } else {
      this.json1 = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2052",

            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "data": {
              "startDate": this.interviewDate1,
              "endDate": this.interviewDate1
            }
          }
        }
      }
    }

    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(this.json1)))
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == "20051") {
            this.showLoader = false;
            this.showMessage = true;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.showMessage = false;
            if (res.json.response.data.scheduledList.length > 0) {

              this.errorMessage = false;
              this.listOfData1 = res.json.response.data.scheduledList;
            }
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage = true;
            this.showLoader = false;
          }
        });
  };

  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  checkName;
  jobData;
  jobTitles = [];
  openJobs(jobs) {
    this.jobTitles = [];
    this.checkName = jobs.assessorName;
    for (var i = 0; i < jobs.jobList.length; i++) {
      this.jobTitles.push(decodeURIComponent(jobs.jobList[i].jobTitle));
    }
    this.jobData = true;
  }

  changeDate(data) {
    ;
    this.interviewDate1 = ""
    if (data != "") {
      var date = new Date(data);
      this.interviewDate1 = this.datePipe.transform(date, 'dd-MMM-yyyy');
    }
    this.listingSchedules();
  }
}