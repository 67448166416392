import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { UserService } from '../user.service';



@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

  showLoader = false;
  listTestimony = [];
  tab;
  errorMessage;
  url = localStorage.getItem("url");

  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private user: UserService) { }

  ngOnInit() {
    this.listingTestimonial();
  }

  searchByName;
  searchByRole;
  searchByCompanyName;
  trimmedSearchByName;
  trimmedSearchByRole;
  trimmedSearchByCompanyName;
  currentPage:number=1;
  currentTab:number=1;
  itemsPerPage:number=10;
  totalItems:number=0;

  testPagination(tab){
      if(this.tab == 1){
        this.listingTestimonial()
      }else{
        this.listingDisabledTestimonial()
      }

  }
  listingTestimonial() {

    this.showLoader = true;
    ;
    this.listTestimony = [];
    this.tab = 1;

    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
    }

    if (this.searchByName !== undefined) {
      this.trimmedSearchByName = this.searchByName.trim();
    }

    if (this.searchByRole !== undefined) {
      this.trimmedSearchByRole = this.searchByRole.trim();
    }

    if (this.searchByCompanyName !== undefined) {
      this.trimmedSearchByCompanyName = this.searchByCompanyName.trim();
    }

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2067",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "disabled": false,
            "name": this.trimmedSearchByName,
            "role": this.trimmedSearchByRole,
            "companyName": this.trimmedSearchByCompanyName
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == "0") {
            this.searchDisabledByName = "";
            this.searchDisabledByRole = "";
            this.searchDisabledByCompanyName = "";

            this.showLoader = false;
            this.listTestimony = res.json.response.data.testimonials;
            this.totalItems = res.json.response.data.count
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  searchDisabledByName;
  searchDisabledByRole;
  searchDisabledByCompanyName;
  trimmedSearchDisabledByName;
  trimmedSearchDisabledByRole;
  trimmedSearchDisabledByCompanyName;
  listingDisabledTestimonial() {

    this.tab = 2;
    this.showLoader = true;
    
    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
    }
    if (this.searchDisabledByName !== undefined) {
      this.trimmedSearchDisabledByName = this.searchDisabledByName.trim();
    }

    if (this.searchDisabledByRole !== undefined) {
      this.trimmedSearchDisabledByRole = this.searchDisabledByRole.trim();
    }

    if (this.searchDisabledByCompanyName !== undefined) {
      this.trimmedSearchDisabledByCompanyName = this.searchDisabledByCompanyName.trim();
    }

    this.listTestimony = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2067",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "disabled": true,
            "name": this.trimmedSearchDisabledByName,
            "role": this.trimmedSearchDisabledByRole,
            "companyName": this.trimmedSearchDisabledByCompanyName
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == "0") {
            this.searchByName = "";
            this.searchByRole = "";
            this.searchByCompanyName = "";

            this.showLoader = false;
            this.listTestimony = res.json.response.data.testimonials;
            this.totalItems = res.json.response.data.count
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  display;
  addPopup() {
    this.name = "";
    this.role = "";
    this.designation = "";
    this.companyName = "";
    this.testimony = "";
    this.display = 'block';
  }

  name; role; designation; companyName; testimony
  addTestimonial() {
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2066",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "name": this.name,
            "role": this.role,
            "designation": this.designation,
            "companyName": this.companyName,
            "testimony": this.testimony
          }
        }
      }
    }
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Added successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.display = 'none';
              this.listingTestimonial();
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  }

  TestimonialId = "";
  displayEdit;
  editTestimonial(item) {
    this.TestimonialId = item.id;
    this.name = item.name;
    this.companyName = item.companyName;
    this.testimony = item.testimony;
    this.displayEdit = 'block';
  }

  OnClose() {
    this.displayEdit = 'none';
  }
  OnCloseHandled() {
    this.display = 'none';
  }

  displayView;
  TestimonialData = "";
  viewTestimonial(item) {
    ;
    this.TestimonialData = item.testimony;
    this.displayView = 'block';
  }

  OnCloseMod() {
    this.displayView = 'none';
  }


  update = function () {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2068",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.TestimonialId,
            "name": this.name,
            "companyName": this.companyName,
            "testimony": this.testimony
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Updated successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            //$('#editModal').modal('hide');
            this.listingTestimonial();
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }

  assignId = "";
  disableTestimonial(TestimonialId) {
    ;
    ;
    this.assignId = TestimonialId.id;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2068",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": true,
            "id": this.assignId,
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingTestimonial();
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Disabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  id;
  enableTestimonial(id) {
    ;
    this.id = id;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2068",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": false,
            "id": id
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingTestimonial();
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Enabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  convertDate(date) {
    return new Date(date);
  };

  commonSearch() {
    if (this.tab == 0) {
      this.listingTestimonial();
    } else if (this.tab == 1) {
      this.listingDisabledTestimonial();
    }
  }
}
