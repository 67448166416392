import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { UserService } from '../user.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-candidates',
    templateUrl: './candidates.component.html',
    styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {

    tab;
    url = localStorage.getItem("url");

    constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private user: UserService) { }

    ngOnInit() {
        this.listingCandidateJobs("")
    }

    comments: string = '';
    inputControl: FormControl = new FormControl('');
    interviews2 = [];
    interviews = [];
    candskills = [];
    imageshowRec; skills2;
    errorMessage; data; photoId; skillsarray
    
    viewCandDetails(item) {
        this.imageshowRec = "";
        this.skills2 = [];
        this.candskills = [];
        ;

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2018",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": item
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        //$('#candidateModal').modal('show');
                        this.data = res.json.response.data.profile;
                        this.photoId = this.data.photoId;
                        this.getPhoto(this.photoId);
                        this.skillsarray = res.json.response.data.interviews;
                        this.display = 'block';

                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }

    display;
    OnCloseHandled() {
        this.imageshowRec = "";
        this.display = 'none';
        //$('#viewEmpModal').modal('hide');
    }
    canPagination(tab){
        if(tab == 1){
            this.listingCandidateJobs("")
        }else{
            this.listingDisabledCandidates("")
        }
    }
    showListCandidates;
    showProfile;
    showLoader;
    searchSkill;
    candidatelist = [];
    searchName; searchEmail; searchMobile; searchExp; createdDate; searchInterview; pagination;
    count; approve;
    trimmedSearchName;
    trimmedSearchEmail;
    trimmedSearchMobile;
    trimmedSearchExp;
    trimedSearchSkill
    listingCandidateJobs(status) {
        this.tab = 1;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.searchName !== undefined) {
            this.trimmedSearchName = this.searchName.trim();
        }

        if (this.searchEmail !== undefined) {
            this.trimmedSearchEmail = this.searchEmail.trim();
        }

        if (this.searchMobile !== undefined) {
            this.trimmedSearchMobile = this.searchMobile.trim();
        }

        if (this.searchExp !== undefined) {
            this.trimmedSearchExp = this.searchExp.trim();
        }

        if (this.searchSkill !== undefined) {
            this.trimedSearchSkill = this.searchSkill.trim();
        }

        this.showListCandidates = true;
        this.showProfile = false;
        this.showLoader = true;
        /*this.showSearchCandidates = false;*/


        var skill = "";
        if (this.searchSkill != undefined) {
            skill = this.searchSkill;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.candidatelist;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2017",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset":(this.currentPage - 1) * this.itemsPerPage,
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        "name": this.trimmedSearchName,
                        "email": this.trimmedSearchEmail,
                        "mobile": this.trimmedSearchMobile,
                        "exp": this.trimmedSearchExp,
                        "createdDate": this.createdDate,
                        "skill": this.trimedSearchSkill,
                        "interviewTaken": this.searchInterview,
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "disabled": false,
                        // "offset": this.pagination


                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == "20051") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == "0") {

                        this.searchDisabledName = "";
                        this.searchDisabledEmail = "";
                        this.searchDisabledMobile = "";
                        this.searchDisabledExp = "";
                        this.searchDisabledSkill = "";

                        this.showLoader = false;
                        this.candidatelist = res.json.response.data;
                        this.count = res.json.response.count.candidatelist;
                        this.totalItems = res.json.response.count
                        this.tab = 1;
                        // this.displayComment = 'block';
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    }

                });
    };

    searchDisabledName;
    searchDisabledEmail;
    searchDisabledMobile;
    searchDisabledExp;
    searchDisabledSkill;
    trimmedsearchDisabledName;
    trimmedsearchDisabledEmail;
    trimmedsearchDisabledMobile;
    trimmedsearchDisabledExp;
    trimmedsearchDisabledSkill;
    currentPage:number=1;
    currentTab:number=1;
    itemsPerPage:number=10;
    totalItems:number=0;
    listingDisabledCandidates(status) {
        this.tab = 2;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.searchDisabledName !== undefined) {
            this.trimmedsearchDisabledName = this.searchDisabledName.trim();
        }

        if (this.searchDisabledEmail !== undefined) {
            this.trimmedsearchDisabledEmail = this.searchDisabledEmail.trim();
        }

        if (this.searchDisabledMobile !== undefined) {
            this.trimmedsearchDisabledMobile = this.searchDisabledMobile.trim();
        }

        if (this.searchDisabledExp !== undefined) {
            this.trimmedsearchDisabledExp = this.searchDisabledExp.trim();
        }

        if (this.searchDisabledSkill !== undefined) {
            this.trimmedsearchDisabledSkill = this.searchDisabledSkill.trim();
        }

        this.showListCandidates = true;
        this.showProfile = false;
        this.showLoader = true;
        ;
        this.candidatelist = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2017",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset":(this.currentPage - 1) * this.itemsPerPage,
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        "name": this.trimmedsearchDisabledName,
                        "email": this.trimmedsearchDisabledEmail,
                        "mobile": this.trimmedsearchDisabledMobile,
                        "exp": this.trimmedsearchDisabledExp,
                        "skill": this.trimmedsearchDisabledSkill,
                        "interviewTaken": this.searchInterview,
                        /* "adminId": $cookieStore.get('adminId'),*/

                        "disabled": true,
                        // "offset": this.pagination


                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == "20051") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == "0") {

                        this.searchName = "";
                        this.searchEmail = "";
                        this.searchMobile = "";
                        this.searchExp = "";
                        this.searchSkill = "";

                        this.showLoader = false;
                        this.candidatelist = res.json.response.data;
                        this.totalItems = res.json.response.count;
                        this.approve = -1;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    keyskills = [];
    canSkills = [];
    candidate;
    candidateId; firstname; aboutcandidate; photo;
    candidateDetails; data1; data2;
    viewCandProfile1(candidateId) {
        this.candidate = candidateId;
        this.keyskills = [];
        this.canSkills = [];
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2018",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": candidateId
                        /*          "adminId":$cookieStore.get('adminId')*/

                    }
                }
            }
        };

        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        //$location.path('/viewProfile');
                        ;

                        this.candidateDetails = res.json.response.data;
                        this.data1 = res.json.response.data;
                        if (res.json.response.data.education.length > 0) {
                            this.data1.qualification = res.json.response.data.education[0].qualification;
                        }
                        this.data2 = res.json.response.data;
                        if (res.json.response.data.certification.length > 0) {
                            this.data2.name = res.json.response.data.certification[0].name;
                        }

                        this.keyskills = this.candidateDetails.skill;


                        if (this.keyskills.length > 0) {
                            for (var i = 0; i < this.keyskills.length; i++) {
                                if (this.keyskills[i].skillName != undefined) {

                                    if (i == 0) {
                                        this.canSkills[i] = (this.keyskills[i].skillName + " (" + this.keyskills[i].level + ")");
                                    } else {
                                        this.canSkills[i] = ("," + this.keyskills[i].skillName + " (" + this.keyskills[i].level + ")");
                                    }
                                }
                            }
                        }
                        this.candidateId = this.candidateDetails.candidateId;
                        this.firstname = this.candidateDetails.firstname;
                        this.aboutcandidate = this.candidateDetails.aboutcandidate;
                        this.photo = this.candidateDetails.photoId;
                        this.getPhoto(this.candidateDetails.photoId);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }


                });
    };

    newviewCandProfile(candidateId) {
        ;
        if (this.candidate != undefined) {
            localStorage.setItem('candidateId', candidateId);
        }
        this.newCandidateId = this.candidate;
        //this.createviewCandProfile(this.candidate);
    }

    skillsArray = [];
    /*$scope.canSkills =[];*/
    employer = [];
    Interviewvideo = [];
    newCandidateId = "";
    candidateDetails1 = [];
    aboutCandDisp; candDate; skillDisp; defaultPhoto; videoid; data3; noEmployers; skills1; skill;
    noEducation; noProject; data4; noCertification; lastname; displayname; currentaddress;
    permanentaddress; mobile; MobileValidated; nointerviews
    viewCandProfile(item) {
        this.showListCandidates = false;
        this.showProfile = true;
        this.employer = [];
        this.skillsArray = [];
        this.Interviewvideo = [];

        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2018",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": item
                        /* "employerId": $cookieStore.get("$scope.employid")*/
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.candidateDetails = res.json.response.data.profile;
                        this.candidateDetails1 = res.json.response.data;
                        this.aboutCandDisp = res.json.response.data.profile.aboutCandidate;
                        this.candDate = this.candidateDetails.lastUpdatedTime;
                        this.skillDisp = res.json.response.data.skill;
                        this.photo = this.candidateDetails.photoId;
                        if (this.photo == -1) {
                            this.defaultPhoto = true;
                        } else if (this.photo != -1) {
                            this.getPhoto(this.candidateDetails.photoId);
                        }
                        this.videoid = this.candidateDetails.videoId;
                        //this.getVideoResume(this.candidateDetails.videoId);
                        this.data3 = res.json.response.data;
                        if (res.json.response.data.work.length > 0) {
                            this.noEmployers = false;
                            this.employer = res.json.response.data.work;
                        } else {
                            this.noEmployers = true;
                        }
                        this.skills1 = res.json.response.data;
                        if (res.json.response.data.skill.length > 0) {
                            this.skillsArray = res.json.response.data.skill;
                        }
                        this.data1 = res.json.response.data;
                        if (res.json.response.data.education.length > 0) {
                            this.noEducation = false;
                            this.data1.qualification = res.json.response.data.education[0].qualification;
                        } else {
                            this.noEducation = true;
                        }
                        this.data4 = res.json.response.data;
                        if (res.json.response.data.project.length > 0) {
                            this.noProject = false;
                            this.data4.cproject = res.json.response.data.project[0].cproject;
                        } else {
                            this.noProject = true;
                        }

                        this.data2 = res.json.response.data;
                        if (res.json.response.data.certification.length > 0) {
                            this.noCertification = false;
                            this.data2.name = res.json.response.data.certification[0].name;
                        } else {
                            this.noCertification = true;
                        }
                        this.candidateId = this.candidateDetails.candidateId;
                        this.firstname = this.candidateDetails.firstname;
                        this.lastname = this.candidateDetails.lastname;
                        this.displayname = this.candidateDetails.photoId;
                        this.currentaddress = this.candidateDetails.experience;
                        this.permanentaddress = this.candidateDetails.email;
                        this.mobile = this.candidateDetails.mobile;
                        this.MobileValidated = this.candidateDetails.currentEmployer;
                        this.aboutcandidate = this.candidateDetails.aboutcandidate;
                        if (res.json.response.data.interviews.length > 0) {
                            this.nointerviews = false;
                            this.interviews = res.json.response.data.interviews;
                            for (var k = 0; k < this.interviews.length; k++) {
                                var id = this.interviews[k].videoId;

                                this.getVideoInterviews('https://qa1.jobztop.com:8443/' + "/GroupzFS/downloadvideo?id=" + id);
                            }
                        } else {
                            this.nointerviews = true;
                        }
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };
    imageshow; noVideoResume; video;
    etVideoResume(vid) {
        ;
        this.imageshow = "";
        ;
        var id = vid;
        /* $scope.imageshow= UserService.getVideoFile(id); */
        if (vid != "-1") {
            this.noVideoResume = false;

            this.video = this.user.url + "/GroupzFS/downloadvideo?id=" + id;
            this.changeSource(this.video);
            /*    $scope.playNPause(); */
        } else {
            this.noVideoResume = true;
        }
    }
    trustSrc(src) {
        //return $sce.trustAsResourceUrl(src);
    }
    video1
    getVideoInterviews(url) {
        this.video1 = [
            {
                //src: $sce.trustAsResourceUrl(url),
                type: "video/mp4"
            },
        ];
        this.Interviewvideo.push(this.video1);
    };
    changeSource(url) {
        this.video = [
            {
                //src: $sce.trustAsResourceUrl(url),
                type: "video/mp4"
            },
        ];
    };
    assignId = "";
    deletePopup(candidateId) {
        ;
        this.assignId = candidateId;
        //$('#deletepopup').modal('show');
    }
    candId = "";
    showEnable = false;
    editComment() {
        this.showEnable = true;
    }
    reasonForComment
    commentPopup(candidateId) {
        this.reasonForComment = "";
        if (candidateId.comment != undefined) {
            this.reasonForComment = candidateId.comment;
            // this.showEnable = false;
        } else {
            this.showEnable = true;
        }
        this.candId = candidateId.candidateId;
        //$('#commentpopup').modal('show');
        this.inputControl = new FormControl('');
        this.displayComment = 'block';
        // this.viewCandProfile(candidateId.candidateId);
        this.listingCandidateJobs("");
    }

    //start delete
    reasonForComment1;
    enterComment() {
        // this.candId = "";
        ;
        this.comments = this.inputControl.value
        this.reasonForComment1 = this.reasonForComment;
        /*      $scope.addedComment = "";*/
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2048",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "id": this.candId,
                        /* "adminId":$cookieStore.get('adminId'),*/
                        "type": 1,
                        "comment": this.comments
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {

                        this.reasonForComment1 = "";
                        if (this.tab == 1) {
                            this.listingCandidateJobs("");
                        } else if (this.tab == 2) {
                            this.listingDisabledCandidates("");
                        }
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Comment added successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                        //$('#commentpopup').modal('hide');
                        this.displayComment = 'none';
                        this.listingCandidateJobs("");
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    displayComment;
    OnCloseCommentHandled() {
        this.imageshowRec = "";
        this.displayComment = 'none';
        //$('#viewEmpModal').modal('hide');
    }

    deletejob(candidateId) {
        this.candidateId = candidateId.candidateId
        this.assignId = "";
        ;

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2019",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": this.candidateId,
                        /* "adminId":$cookieStore.get('adminId'),*/
                        "disabled": true
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Disabled successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                        //$('#deletepopup').modal('hide');
                        this.listingCandidateJobs("");
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };

    enableCand(item) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2019",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": item.candidateId,
                        /* "adminId":$cookieStore.get('adminId'),*/
                        "disabled": false
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Enabled successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                        this.listingDisabledCandidates("");
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };

    questionsList;
    candeId;
    releasingLock(item) {
        ;
        this.questionsList = "";
        this.candeId = item.candidateId;

        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2063",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": item.candidateId,
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 20021) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["No Questions Found for this Candidate"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        //                    FlashService.Success("Enabled successfully");

                        //                    $scope.listingDisabledCandidates();
                        //$('#showQuestionaryModal').modal('show');
                        this.questionsList = res.json.response.data.questions;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };
    //        $scope.questComment;
    releaseLock(item, questComment) {
        ;


        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2064",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": this.candeId,
                        "questionId": [item.questionId],
                        "comment": questComment
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Lock Released"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                        //$('#showQuestionaryModal').modal('hide');                    
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };
    datePicker() {
        var currentYear = new Date().getFullYear();
        currentYear = currentYear;
        // $timeout(function () {
        //     if ($scope.model.dob) {
        //         $('#dobdate').combodate({
        //             minYear: 1950,
        //             maxYear: Number(currentYear),
        //             firstItem: 'name'
        //         });
        //         $('#dobdate').combodate('setValue', $scope.model.dob)
        //     } else {
        //         $('#dobdate').combodate({
        //             minYear: 1950,
        //             maxYear: Number(currentYear),
        //             firstItem: 'name'
        //         });
        //     }

        // }, 100);

    }
    skillId = [];
    skillsDropdown = [];
    assessorList = [];
    sillsId = [];
    getCandId = ""
    selectedName1;
    openSchedulingInterviewModel(item) {
        ;
        this.getCandId = item.candidateId;
        this.skillId = [];
        this.skillsDropdown = [];
        this.selectedName1 = "";
        this.assessorList = [];
        this.sillsId = [];
        for (var i = 0; i < item.skills.length; i++) {
            this.skillsDropdown.push({
                name: item.skills[i].skillName,
                id: item.skills[i].skillId
            });
        }
        //$('#showSchedulingInterviewModel').modal('show');
        // this.display = 'block';
    }
    selectedName;
    getAssessorList(status) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2026",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {

                        "approved": 1,
                        "skill": this.selectedName.skillName,
                        "sponsored": false
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No match found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.assessorList = res.json.response.data.assessorList;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };
    setskillsId = "";
    setSkillId(skillsDropdown) {
        this.setskillsId = skillsDropdown.id;
        localStorage.setItem('skillId', skillsDropdown.id);
    }
    AssessorId = ""
    setAssessorId(assessorList) {
        this.AssessorId = ""
            ;
        this.AssessorId = assessorList.assessorId;
        localStorage.setItem('assId', assessorList.assessorId);
    }
    dateTime = "";
    Time = "";
    schedulingInterview(year, time) {
        ;
        this.Time = time + ":" + "00";
        this.dateTime = year + ' ' + this.Time;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2065",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "candidateId": this.getCandId,
                        "assessorId": localStorage.getItem('assId'),
                        "skillId": localStorage.getItem('skillId'),
                        "scheduledTime": this.dateTime
                    }
                }
            }
        }
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.selectedName1 = "";
                        this.showLoader = false;
                        this.dateTime = "";
                        this.Time = "";
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    }
    convertDate(date) {
        return new Date(date);
    };

    skills;
    skillsforJobs(data) {
        /*  ;*/
        var json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5005"
                }
            }
        };

        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames1 = [];
                            var finalNames1 = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i1, el1) {
                            //         if (el1 !== "") {
                            //             if ($.inArray(el1, uniqueNames1) === -1) {
                            //                 uniqueNames1.push(el1);
                            //                 finalNames1.push({
                            //                     name: el1
                            //                 });
                            //             }
                            //         }
                            //     });
                            //     this.skills = finalNames1;
                            // }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }

                });


    };
    multipleskillsforsearch(item) {
        ;
        this.skillId = item.name;
        this.searchSkill = item.name;
    }

    cities;
    citiesForJob(data) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "21",
                    "functiontype": "1047"
                }
            }
        };

        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {

                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {

                        if (res.json.response.statuscode == 0) {

                            var uniqueNames1 = [];
                            var finalNames1 = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i1, el1) {
                            //         if (el1 !== "") {
                            //             if ($.inArray(el1, uniqueNames1) === -1) {
                            //                 uniqueNames1.push(el1);
                            //                 finalNames1.push({
                            //                     name: el1
                            //                 });
                            //             }
                            //         }
                            //     });
                            //     this.cities = finalNames1;
                            // }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    showSearchCandidates; minexp; maxexp;
    searchCandidateList = [];
    searchCandidate(item) {
        this.showListCandidates = false;
        this.showSearchCandidates = true;
        ;
        this.searchCandidateList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2023",
                    "data": {
                        "adminId": localStorage.get('adminId'),
                        "skill": this.arrayvalues,
                        "location": this.arrayofcities,
                        "prefferedLocation": this.arrayofprecities,
                        "min": this.minexp,
                        "max": this.maxexp,
                        "offset": this.pagination
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.searchCandidateList = res.json.response.data;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }

    arrayvalues = [];
    Skills;
    multipleSkills(data) {
        ;
        /*   $scope.filteredArray = [];*/
        if (this.arrayvalues.length < 1) {
            this.arrayvalues.push(data);
            this.Skills = "";
        } else if (this.arrayvalues.length > 0) {
            if (this.arrayvalues.indexOf(this.Skills) == -1) {
                if (this.Skills != "") {
                    this.arrayvalues.push(data);
                    this.Skills = "";
                }
            }
            this.showFilteredArrayAdd();
        };
    };

    filteredArray;
    _lastSkillIndex; _firstSkillIndex;
    isRightSkillActive
    showFilteredArray() {
        ;
        this.filteredArray = [];

        if (this.arrayvalues.length >= 3) {
            this.isRightSkillActive = true;
            for (var i = this._firstSkillIndex; i <= this._lastSkillIndex; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': this.arrayvalues[i].substr
                });
            }
        } else {
            for (var i = this._firstSkillIndex; i <= this.arrayvalues.length - 1; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': this.arrayvalues[i].substr
                });
            }
        }
    };

    showFilteredArrayAdd() {
        ;
        this.filteredArray = [];

        if (this.arrayvalues.length >= 3) {
            this.isRightSkillActive = true;
            for (var i = this._firstSkillIndex; i <= this._lastSkillIndex; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': this.arrayvalues[i].substr
                });
            }
        } else {
            for (var i = this._firstSkillIndex; i <= this.arrayvalues.length - 1; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': (this.arrayvalues[i].substr)
                });
            }
        }
        if (this.arrayvalues.length > 2) {
            this.showNext();
        }
    };

    showFilteredArrayRemove() {
        ;
        this.filteredArray = [];
        if (this.arrayvalues.length >= 3) {
            this.isRightSkillActive = true;
            for (var i = 0; i == this._lastSkillIndex; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': this.arrayvalues[i].substr
                });
            }
        } else {
            for (var i = 0; i <= this.arrayvalues.length - 1; i++) {
                this.filteredArray.push({
                    'data': this.arrayvalues[i],
                    'shortData': this.arrayvalues[i].substr
                });
            }
        }
        if (this.arrayvalues.length > 2) {
            this.showPrev();
        }
    };
    /** Show actor preference **/
    isLeftSkillActive;
    showPrev() {
        ;

        if (this._firstSkillIndex > 0) --this._lastSkillIndex;
        this._firstSkillIndex = (this._firstSkillIndex > 0) ? --this._firstSkillIndex : 0;

        if (this._firstSkillIndex == 0) {
            this.isLeftSkillActive = false;
            this.isRightSkillActive = true;
        }
        /*    $scope.multipleSkills();*/
        this.showFilteredArray();
    };

    showNext() {
        ;
        if (this._lastSkillIndex < this.arrayvalues.length - 1) ++this._firstSkillIndex;
        this._lastSkillIndex = (this._lastSkillIndex < this.arrayvalues.length - 1) ? ++this._lastSkillIndex : this.arrayvalues.length - 1;

        if (this._lastSkillIndex > 0) {
            this.isLeftSkillActive = true;
            this.isRightSkillActive = false;
        }

        if (this._lastSkillIndex == this.arrayvalues.length - 1) {
            this.isLeftSkillActive = true;
            this.isRightSkillActive = true;
        }

        //                this.multipleSkills();
        this.showFilteredArray();
    };

    roleValue; index; tooltip; rolesAdded;
    removeSkills(index, data) {
        ;
        if (this.arrayvalues !== null) {

            this.index = this.arrayvalues.indexOf(data);

            this.arrayvalues.splice(index, 1);

            this.showFilteredArrayRemove();
            this.tooltip = false;
        } else {
            this.tooltip = true;
        }
        this.rolesAdded = true;
    };

    removeCities(index, data) {
        ;




        if (this.arrayofcities !== null) {

            index = this.arrayofcities.indexOf(data);

            this.arrayofcities.splice(index, 1);

            this.showFilteredCityRemove();
            this.tooltip = false;



        } else {
            this.tooltip = true;
        }


        this.rolesAdded = true;
    };


    removePreCities(index, data) {
        ;




        if (this.arrayofprecities !== null) {

            index = this.arrayofprecities.indexOf(data);

            this.arrayofprecities.splice(index, 1);


            this.showFilteredPreCityRemove();
            this.tooltip = false;



        } else {
            this.tooltip = true;
        }


        this.rolesAdded = true;
    };


    //start cities


    arrayofcities = []; city;
    multipleCities(data) {
        ;
        if (this.arrayofcities.length < 1) {
            this.arrayofcities.push(data);
            this.city = "";
        } else if (this.arrayofcities.length > 0) {
            if (this.arrayofcities.indexOf(this.city) == -1) {



                if (this.city != "") {
                    this.arrayofcities.push(data);

                    this.city = "";

                }
            }

            this.showFilteredCityAdd();

        }





    };



    filteredCity; isRightCityActive; _firstCityIndex; _lastCityIndex;
    howFilteredCity() {
        ;


        this.filteredCity = [];

        if (this.arrayofcities.length >= 3) {
            this.isRightCityActive = true;
            for (var i = this._firstCityIndex; i <= this._lastCityIndex; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': this.arrayofcities[i].substr
                });
            }
        } else {
            for (var i = this._firstCityIndex; i <= this.arrayofcities.length - 1; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': this.arrayofcities[i].substr
                });
            }
        }


    };

    showFilteredCityAdd() {
        ;


        this.filteredCity = [];

        if (this.arrayofcities.length >= 3) {
            this.isRightCityActive = true;
            for (var i = this._firstCityIndex; i <= this._lastCityIndex; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': this.arrayofcities[i].substr
                });
            }
        } else {
            for (var i = this._firstCityIndex; i <= this.arrayofcities.length - 1; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': (this.arrayofcities[i].substr)
                });
            }
        }
        if (this.arrayofcities.length > 2) {
            this.showNextCity();
        }


    };

    showFilteredCityRemove() {
        ;


        this.filteredCity = [];

        if (this.arrayofcities.length >= 3) {
            this.isRightCityActive = true;
            for (var i = 0; i == this._lastCityIndex; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': this.arrayofcities[i].substr
                });
            }
        } else {
            for (var i = 0; i <= this.arrayofcities.length - 1; i++) {
                this.filteredCity.push({
                    'data': this.arrayofcities[i],
                    'shortData': this.arrayofcities[i].substr
                });
            }
        }
        if (this.arrayofcities.length > 2) {
            this.showPrevCity();
        }


    };
    /** Show actor preference **/
    isLeftCityActive
    showPrevCity() {
        ;

        if (this._firstCityIndex > 0) --this._lastCityIndex;
        this._firstCityIndex = (this._firstCityIndex > 0) ? --this._firstCityIndex : 0;

        if (this._firstCityIndex == 0) {
            this.isLeftCityActive = false;
            this.isRightCityActive = true;
        }

        /*    this.multipleSkills();*/
        //this.showFilteredCity("");

    };


    showNextCity() {
        ;
        if (this._lastCityIndex < this.arrayofcities.length - 1) ++this._firstCityIndex;
        this._lastCityIndex = (this._lastCityIndex < this.arrayofcities.length - 1) ? ++this._lastCityIndex : this.arrayofcities.length - 1;

        if (this._lastCityIndex > 0) {
            this.isLeftCityActive = true;
            this.isRightCityActive = false;
        }

        if (this._lastCityIndex == this.arrayofcities.length - 1) {
            this.isLeftCityActive = true;
            this.isRightCityActive = true;
        }

        //                this.multipleSkills();
        //this.showFilteredCity();
    };



    arrayofprecities = [];
    preLocation;
    multiplePreCities(data) {
        ;
        if (this.arrayofprecities.length < 1) {
            this.arrayofprecities.push(data);
            this.preLocation = "";

        } else if (this.arrayofprecities.length > 0) {
            if (this.arrayofprecities.indexOf(this.preLocation) == -1) {



                if (this.preLocation != "") {
                    this.arrayofprecities.push(data);

                    this.preLocation = "";

                }
            }

        }

        this.showFilteredPreCityAdd();



    };
    filteredPreCity; _lastPreCityIndex; isRightPreCityActive; _firstPreCityIndex
    showFilteredPreCity() {
        ;
        this.filteredPreCity = [];

        if (this.arrayofprecities.length >= 3) {
            this.isRightPreCityActive = true;
            for (var i = this._firstPreCityIndex; i <= this._lastPreCityIndex; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': this.arrayofprecities[i].substr
                });
            }
        } else {
            for (var i = this._firstPreCityIndex; i <= this.arrayofprecities.length - 1; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': this.arrayofprecities[i].substr
                });
            }
        }


    };

    showFilteredPreCityAdd() {
        ;


        this.filteredPreCity = [];

        if (this.arrayofprecities.length >= 3) {
            this.isRightPreCityActive = true;
            for (var i = this._firstPreCityIndex; i <= this._lastPreCityIndex; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': this.arrayofprecities[i].substr
                });
            }
        } else {
            for (var i = this._firstPreCityIndex; i <= this.arrayofprecities.length - 1; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': (this.arrayofprecities[i].substr)
                });
            }
        }
        if (this.arrayofprecities.length > 2) {
            this.showNextPreCity();
        }


    };

    _lastCityPreIndex;
    showFilteredPreCityRemove() {
        ;


        this.filteredPreCity = [];

        if (this.arrayofprecities.length >= 3) {
            this.isRightPreCityActive = true;
            for (var i = 0; i == this._lastCityPreIndex; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': this.arrayofprecities[i].substr
                });
            }
        } else {
            for (var i = 0; i <= this.arrayofprecities.length - 1; i++) {
                this.filteredPreCity.push({
                    'data': this.arrayofprecities[i],
                    'shortData': this.arrayofprecities[i].substr
                });
            }
        }
        if (this.arrayofprecities.length > 2) {
            this.showPrevPreCity();
        }


    };
    /** Show actor preference **/
    isLeftPreCityActive;
    showPrevPreCity() {
        ;

        if (this._firstPreCityIndex > 0) --this._lastPreCityIndex;
        this._firstPreCityIndex = (this._firstPreCityIndex > 0) ? --this._firstPreCityIndex : 0;

        if (this._firstPreCityIndex == 0) {
            this.isLeftPreCityActive = false;
            this.isRightPreCityActive = true;
        }

        /*    this.multipleSkills();*/
        this.showFilteredPreCity();

    };


    showNextPreCity() {
        ;
        if (this._lastPreCityIndex < this.arrayofprecities.length - 1) ++this._firstPreCityIndex;
        this._lastPreCityIndex = (this._lastPreCityIndex < this.arrayofprecities.length - 1) ? ++this._lastCityIndex : this.arrayofprecities.length - 1;

        if (this._lastPreCityIndex > 0) {
            this.isLeftPreCityActive = true;
            this.isRightPreCityActive = false;
        }

        if (this._lastPreCityIndex == this.arrayofprecities.length - 1) {
            this.isLeftPreCityActive = true;
            this.isRightPreCityActive = true;
        }

        //                this.multipleSkills();
        this.showFilteredPreCity();
    };


    search() {
        ;
        /* if($scope.model.search == ""){
           $scope.model.search = undefined; 
        }*/
        this.listingCandidateJobs("")
        this.candidatelist = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2025",
                    "data": {
                        "adminId": localStorage.get('adminId'),
                        "emailOrMobile": this.search
                    }
                }
            }
        };
        this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
            .subscribe(
                (res: any) => {
                    ;

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.candidatelist = res.json.response.data;

                        /* for(var i=0;this.employeelist.length;i++) {
            $scope.allJobIds.push($scope.employeelist[i].jobId);
                     }*/
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }


                });

    }
    
    getresume; resume;
    getResume(candidateId) {
        if (candidateId != undefined) {
            this.candidate = candidateId;
        }
        ;

        var resumeJson = {
            "json": {
                "request": {
                    "candidateid": this.candidate,
                    "type": 2
                }
            }
        };

        this.getresume = this.url + "/JobzTop/GetFile?request=" + resumeJson;
        //this.resume = UserService.getFile(resumeJson);
    }

    commonSearch() {
        ;
        if (this.tab == 1) {
            this.listingCandidateJobs("");
        } else if (this.tab == 2) {
            this.listingDisabledCandidates("");
        }
    }
    imageshow1;
    getPhoto(pid) {
        ;
        this.imageshow1 = "";
        ;
        var id = pid + "&resolution=640X480";
        //this.imageshow1 = this.downloadPhoto(id);
    }

}
