import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Router, NavigationExtras } from '@angular/router';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgFlashMessageService } from 'ng-flash-messages';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  tab: any;
  rform: FormGroup;
  post: any;
  display = 'none';
  resetEmail: any;
  startSpinner = false;
  successMessage = false;
  errorMessage = false;
  Success = "";
  Error = "";
  url = localStorage.getItem("url");


  constructor(private http: HttpClient, private router: Router, private fb: FormBuilder, private ngFlashMessageService: NgFlashMessageService, private user: UserService) {

    this.rform = fb.group({
      'tpassword': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/),
          Validators.minLength(8),
          Validators.maxLength(20)
        ])
      ],
      'useremail': [null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
        ])
      ],
      'validate': '',
    })
  }

  ngOnInit() {
  }
  clearValues() {
    this.rform.reset()
  }
  adminId;
  sessionId;
  login(postDetails) {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2001",
          "email": postDetails.useremail,
          "password": postDetails.tpassword
        }
      }
    }
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          if (res.success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
            window.scrollTo(500, 0);
          } else {
            if (res.json.response.statuscode == "0") {
              this.adminId = res.json.response.adminId;
              localStorage.setItem('adminId', this.adminId);
              this.sessionId = res.json.response.session.sessionid;
              localStorage.setItem('sessionId', this.sessionId);
              this.router.navigate(['Dashboard']);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });

  }

}
