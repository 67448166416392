import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserService } from '../user.service';
import { Http } from '@angular/http';
import { DatePipe } from '@angular/common';
import "froala-editor/js/froala_editor.pkgd.min.js";
import { Router, NavigationExtras } from '@angular/router';

import { NgFlashMessageService } from 'ng-flash-messages';

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.css']
})
export class PostJobComponent implements OnInit {
  selectedItem=2;
 url = localStorage.getItem("baseUrl") ;
  companyAdmin:any = localStorage.getItem('companyAdmin');
  inviteform:FormGroup;
  companyPhoto:any;
  profileLogo:any;
  companyLogoId:any;
  profilePhotoId:any;
  companyName:any;
  profileName:any;
  listing : any;
  postJob = false;
  showMyJobs = false;
  showAllJobs = false;
  showCandidates = false;
  tab:any;
  myJobResults = [];
  myJobResultsList = [];
  approved1:any;
  allApproved1:any;
  allJobResults = [];
  allJobResultsList = [];
  moreDetails = [];
  display = 'none';
  matchingCandidateList  = [];
  appliedCandidateList = [];
  approachCandidateList = [];
  shortListCandidateList = [];
  assessedCandidateList = [];
  offeredCandidateList = [];
  acceptCandidateList = [];
  withdrawCandidateList = [];
  rejectCandidateList = [];
  interestCandidateList=[];
  candidate : any;
  tab1 =1;
  public pieChartLabels:string[] = ['Matching', 'Interested','Approached', 'Applied',  'Shorlisted', 'In Assessment', 'Offered'];
  public pieData:number[];
  public pieData1:number[];
  public pieChartType:string = 'pie';
  p: number = 1;
  matchingJobztopRated:any=false;
  matchingVideo:any=false;
  matchingSearchName = "";
  candidateJobztopRated:any=false;
  candidateVideo:any=false;
  candidateSearchName = "";
  appliedJobztopRated:any=false;
  appliedVideo:any=false;
  appliedSearchName = "";
  approachJobztopRated:any=false;
  approachVideo:any=false;
  approachSearchName = "";
  shortlistJobztopRated:any=false;
  shortlistVideo:any=false;
  shortlistSearchName = "";
  assessedJobztopRated:any=false;
  assessedVideo:any=false;
  assessedSearchName = "";
  offeredJobztopRated:any=false;
  offeredVideo:any=false;
  offeredSearchName = "";
  acceptJobztopRated:any=false;
  acceptVideo:any=false;
  acceptSearchName = "";
  withdrawJobztopRated:any=false;
  withdrawVideo:any=false;
  withdrawSearchName = "";
  rejectJobztopRated:any=false;
  rejectVideo:any=false;
  rejectSearchName = "";
  Error = "";
  errorMessage = false
  Success = "";
  successMessage = false;
  myJobsApprove = "";
  rejectCandidate:any;
  reasonForRejection = "";
  display1 = 'none';
  rejectedBy = "";
  rejectedBy1 = "";
  display2 = 'none';
  commentsForRejection:any;
  commentsForRejection1:any;
  assessedCandiateFName:any;
  assessedCandiateLName:any;
  assessmentResponse:any;
  sponsoredAssessments:any;
  freeLanceAssessments:any;
  assessmentskills:any;
  freelancerList = [];
  sponsorList = [];
  sponsorList2 = [];
  sponsorList3 = [];
  display3 = 'none';
  deleteJobId:any;
  ErrorModal :any;
  errorMessageModal = false;
  SuccessModal :any;
  successMessageModal = false;
  display4 = 'none';
  panelPresent = false;
  bPanelList1:any;
  noPanel = false;
  fetchPanelDetails:any;
  mappedJobsPanelListNoValues = false;
  fetchPanelAssessorDetails:any;
  mappedJobsPanelList = [];
  panelId:any;
  display5 = 'none';
  interviewDate:any;
  interviewDate1:any;
  listOfData1:any;
  jobData = false;
jobTitles = [];
checkName = "";
display6 = 'none';
inviteEmail:any;
inviteMobile:any;
inviteCode:any;
inviteName:any;
invitedCandidateList:any;
showCandidateProfile = false;  
candidateProfileList = [];
dob:any;
showPostJob = false;
showEditJob = false;
experienceValue:any;
jobTypeValue:any;
selectedCity:any;
experienceLevel:any;
skillname1:any;
levelname:any;
jobType:any;
experience
arrayvalues2 =[];
arrayvalues = [];
arrayValuesSkill1 = [];
postForEmployerName:any;
postJobTitle:any;
numberOfPositions:any;
countryId:any;
stateId:any;
cityId:any;
industryId:any;
categoryId:any;
country = "";
state = "";
city = "";
industry = "";
category = "";
listSkill = "";
skills =[];
categories = [];
industries = [];
cities = [];
states = [];
countries = [];
afterCategoryDisplay = false;
beforeCategoryDisplay = false;
afterIndustryDisplay = false;
beforeIndustryDisplay = false;
afterCityDisplay = false;
beforeCityDisplay = false;
afterStateDisplay = false;
beforeStateDisplay = false;
afterCountryDisplay = false;
beforeCountryDisplay = false;
editcategory:any;
editindustry:any;
editcity : any;
editstate : any;
editcountry : any;
editcountry1 : any;
postJobDescription:any;
editjobid:any;
editcompanyName:any;
editcreatedTime:any;
editexpiryDate:any;
editpostJobTitle:any;
editexperienceLevel:any;
editnumberOfPositions:any;
editjobType:any;
editpostJobDescription:any;
viewJobdetails = [];
jobTitle1:any;
  locations1:any;
  recskill1="";
  jobRecruiter:any;
  allJobTitle1:any;
  allLocations1:any;
  allRecskill1="";
  postJobztopRated = false;
empName1:any;
round3Name:any;
showLoader = false;
data:any;
openPostJob1:any;
 changingJob:any;
 showtool=false;
 maxCount:any;
 currentPage = 1;
 disableValue : any;
  allJobsList = false;
  scheduleSkillsList:any;
  display9 = 'none';
  scheduleSkills:any;
  ScheduleAssessors:any;
  scheduleItem:any;
  forShortList = false;
  forInvite = false;
  modalValue:any;
  roundVal:any;
  scheduleAssessorDate:any;
  scheduleAssessorId:any;
  scheduleSkillId:any;
  scheduleAssessorTime:any
  display10 = 'none';
  scheduleDetails:any;
  changescheduleSkills:any;
  changescheduleAssessors:any
  changescheduleDate:any;
  changescheduleTime:any;
  scheduleDate:any;
  scheduleTime:any;
  fromTime:any;
  json5:any;
  scheduledList2 = [];
  changeScheduleAssessors:any;
  hours:any;
  minutes:any;
  dataQueueId:any;
  profileValue:any;
  scheduledList1 = [];
  stateOfAss:any;
  assApprove:any;
  roundValues:any;
  globalData:any;
  globalCandidateData:any;
  display8 = 'none';
  globalAssessmentDetails:any;
  json2:any;
  display11 = 'none';
  interviewIds:any;
  reasonForDisable:any;
  arrayvalues5 = [];
  json1:any;
  showpanelId1 = false;
  showpanelId2 = false;
  showpanelId3 = false;
  assignedPanelName:any;
  checkValue:any;
  panelId1:any;
  panelId2:any;
  assPanelId:any;
queueId:any;
valueShow:any;
profileval:any;
  tabValue:any;
  tabJob:any;
  showProfileDetails:any;
  toplistSkill:any;
  additionalskill:any;
  level1:any;
  toplevel:any;
  levelname2:any;
  skillname2:any;
foundRepeatingValue = false;
arrayvaluesNew = [];
arrayvalues3 = [];
arrayvalues4 = [];
json3:any;
editcityId:any;
jobztopRated  = false;
toplistSkill1:any;
additionalListSkill:any;
level:any;
addlevel1:any;
listOfManageEmployers1 = [];
display7 = 'none';
currentId:any;
totalCredits:any; 
display12 = 'none';
candidateJobTitle:any;
noOfQuestions:any;
showQuestionare = false;
tran=false;
questionSkillId:any;
  questionsArray = [];
  questionSkill:any;
  question:any;
  suggestedQuestion="";
  spinner=false;
  savedSkills=[];
  buildQuationnare = [];
  addedQuestions=[];
  questionTime="";
  suggestedQuestions=[];
  suggestedQuestions1:any=[];
  maxTime:any;
  questionsList=[];
  skillId="";
  interestJobztopRated=false;
  interestVideo=false;
  interestSearchName="";
  skillId1="";
  candQuestionsArray=[];
  viewAssessmentModal=false;
  sponsoredAssessments2=[];
  sponsoredAssessments3=[];
  panelDetails = [];
  location1:any;
enableMaxTime=false;
  display13 = 'none';
showProfile=true;
showAssessment=false;
round1Name:any;
round1Summary:any;
round2Name:any;
round2Summary:any;
round3Summary:any;
invitedStatus:any;
m:any;
s:any;
t:any;
seconds:any;
revertState:any;
revertStateId:any;
display14 = 'none';
offerDetails:any;
offerValue:any;
display15 = 'none';
minRange:any;
maxRange:any;
isLinkedinUrl= /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
display16 = 'none';
displayRecMobile = localStorage.getItem("recMobile");
  public options: Object = {
    events : {
      'froalaEditor.html.set': function (e, editor) {
        editor.events.trigger('charCounter.update');
      }
    },
    charCounterCount: true,
    charCounterMax: 3000,
    fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        toolbarButtons: ['fontFamily', '|', 'fontSize', '|', 'paragraphFormat', '|', 'bold', 'italic', 'underline','formatOL', 'formatUL', 'inlineStyle', 'undo', 'redo', 'codeView'],
      //'insertImage', 'insertLink', 'insertFile', 'insertVideo',
       // Define new inline styles.
       inlineStyles: {
        'Big Red': 'font-size: 20px; color: red;',
        'Small Blue': 'font-size: 14px; color: blue;'
      }
  };
  postingJob(){
    this.showLoader=true;
    this.tran=true;
    this.spinner=true;
    this.arrayvalues5 = [];
  if(this.arrayvalues4.length>0){
    for(var i=0;i<this.arrayvalues4.length;i++){
      this.arrayvalues5.push({
        "skillName":encodeURIComponent(this.arrayvalues4[i].skillName), 
        "level":this.arrayvalues4[i].level, 
        "mandatory": this.arrayvalues4[i].mandatory,
        "preferable": this.arrayvalues4[i].preferable,
        "manOrPre": this.arrayvalues4[i].manOrPre
        })
    }
  }
  if(this.arrayvalues3.length>0){
    for(var k=0;k<this.arrayvalues3.length;k++){
      this.arrayvalues5.push({
        "skillName":encodeURIComponent(this.arrayvalues3[k].skillName), 
        "level":this.arrayvalues3[k].level, 
        "mandatory": this.arrayvalues3[k].mandatory,
        "preferable": this.arrayvalues3[k].preferable,
        "manOrPre": this.arrayvalues3[k].manOrPre
        })
    }
  }
  if(this.minRange == "" || this.minRange == null || this.minRange == undefined){
    this.minRange=undefined;
  }
  if(this.maxRange == "" || this.maxRange == null|| this.maxRange == undefined ){
    this.maxRange=undefined;
  }
  if(this.empName1 == undefined || this.empName1 == '' || this.empName1 == null || this.empName1 == ""){
    this.postForEmployerName = "";
    this.json3 = {
      "json": {
        "request": {
          "servicetype": "5",
          "functiontype": "5000",
            
          "session":{
                "sessionid":localStorage.getItem("session")
                 },    
          "data": {
         "companyAdmin": this.companyAdmin,
         "jobTitle": encodeURIComponent(this.postJobTitle),
         "jobDescription":encodeURIComponent(this.postJobDescription),
         "numberOfPositions":this.numberOfPositions,
          "location": {
              "country": this.countryId,
              "state": this.stateId,
              "city": this.cityId
            },
          "type": this.jobType,
          "industry":this.industryId,
          "category":this.categoryId,
          "experienceLevel": this.experienceLevel,
          "min":this.minRange,
          "max":this.maxRange,
          "keySkills": this.arrayvalues5,
          "assessment":this.postJobztopRated
          
          }
        }
      }
    }
  }
  else{
    this.postForEmployerName = this.empName1;
    this.json3 = {
      "json": {
        "request": {
          "servicetype": "5",
          "functiontype": "5000",
            
          "session":{
                "sessionid":localStorage.getItem("session")
                 },    
          "data": {
         "postedFor":this.postForEmployerName,
         "companyAdmin": this.companyAdmin,
         "jobTitle": encodeURIComponent(this.postJobTitle),
         "jobDescription": encodeURIComponent(this.postJobDescription),
         "numberOfPositions":this.numberOfPositions,
          "location": {
              "country": this.countryId,
              "state": this.stateId,
              "city": this.cityId
            },
          "type": this.jobType,
          "industry":this.industryId,
          "category":this.categoryId,
          "experienceLevel": this.experienceLevel,
          "min":this.minRange,
          "max":this.maxRange,
          "keySkills": this.arrayvalues5,
          "assessment":this.postJobztopRated
          
          }
        }
      }
    }
  }
    
  this.http.post(this.url +'/JobzTop/Job?request=' , JSON.stringify(this.json3))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "Success" || res.json.response.statusmessage == "Success" ){
   
   
    this.showLoader=false;
    this.tran=false;
    this.spinner=false;
    localStorage.removeItem('empName');
    localStorage.setItem('empName',"");
    this.empName1 = "";
   
    this.ngFlashMessageService.showFlashMessage({
      // Array of messages each will be displayed in new line
      messages: [res.json.response.statusmessage], 
      // Whether the flash can be dismissed by the user defaults to false
      dismissible: true, 
      // Time after which the flash disappears defaults to 2000ms
      timeout: 2000,
      // Type of flash message, it defaults to info and success, warning, danger types can also be used
      type: 'success'
    });
   
    // this.Success = res.json.response.statusmessage;
    // this.successMessage = true;
    // localStorage.removeItem('empName');
    // setTimeout(() => {
    //   this.successMessage = false;
    //   this.Success = "";
    // }, 5000);
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
    this.tran=false;
    this.spinner=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  else{
    this.showLoader=false;
    this.tran=false;
    this.spinner=false;
    // this.Error = res.json.response.statusmessage;
    // this.errorMessage = true;
    // setTimeout(() => {
    //   this.errorMessage = false;
    //   this.Error = "";
    // }, 5000);
    this.ngFlashMessageService.showFlashMessage({
      // Array of messages each will be displayed in new line
      messages: [res.json.response.statusmessage], 
      // Whether the flash can be dismissed by the user defaults to false
      dismissible: true, 
      // Time after which the flash disappears defaults to 2000ms
      timeout: 2000,
      // Type of flash message, it defaults to info and success, warning, danger types can also be used
      type: 'danger'
    });
  }
  })
  }
  
  jobExperience(data){
    this.experienceValue = data;
    
  }
  jobTypeSet(data){
    this.jobTypeValue = data;
  }
  countryValues() {
    this.countries=[];              
    var countryJson = {
    "json": {
        "request": {
            "servicetype": "100",
            "functiontype": "101"
        }
    }
  }
  this.http.get(this.url +'/JobzTop/Geography?request=' + JSON.stringify(countryJson))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "success"){
    this.countries = res.json.response.data;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  })
  
  }
  
  stateValues(id) {
    this.countryId = id;
    this.states=[];      
    var countryJson = {
    "json": {
        "request": {
            "servicetype": "100",
            "functiontype": "102",
            "data":{
              "countryname":id
          }
        }
    }
  }
  this.http.get(this.url +'/JobzTop/Geography?request=' + JSON.stringify(countryJson))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "success"){
    this.states = res.json.response.data;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  for(var i=0;this.states.length;i++){
   if(this.viewJobdetails[0].location.state == this.states[i].statename){
    this.editstate = this.states[i].statename;
   }
  }
  })
  
  }
  cityValues(id) {
    this.stateId = id;
    this.cities =[];            
    var countryJson = {
    "json": {
        "request": {
            "servicetype": "100",
            "functiontype": "103",
            "data":{
              "countryname":this.countryId,
              "statename":id 
          }
        }
    }
  }
  this.http.get(this.url +'/JobzTop/Geography?request=' + JSON.stringify(countryJson))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "success"){
    this.cities = res.json.response.data;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  for(var i=0;i<this.cities.length;i++){
    if(this.viewJobdetails[0].location.city == this.cities[i].cityname){
     this.editcity = this.cities[i].cityname;
    }
   }
  })
  
  }
  
  setCityValue(data){
    this.cityId = data;
    this.editcityId = data;
  }
  industryValues(){
    this.industries = [];  
    var industryJson = {
       'json': {
           'request': {
               'servicetype': '21',
               'functiontype': '1014',
               "session":{
                       "sessionid":localStorage.getItem("session")
                   },
                "industry":  '_ALL_'
           }
       }
   }
  this.http.get(this.url +'/JobzTop/QuestionBank?request=' + JSON.stringify(industryJson))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "Success"){
    this.industries = res.json.response.data;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  })
  
  }
  categoryvalues(item){
    this.industryId = item;
    this.categories=[];
    var industryJson = {
       'json': {
           'request': {
               'servicetype': '21',
               'functiontype': '1014',
               "session":{
                       "sessionid":localStorage.getItem("session")
                   },
                   "sort":{
                    "sortby":"category",
                    "orderby":"ASC"
                },
                "industry":item
           }
       }
   }
  this.http.get(this.url +'/JobzTop/QuestionBank?request=' + JSON.stringify(industryJson))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "Success"){
    this.categories = res.json.response.data;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  // for(var i=0;this.categories.length;i++){
  //   if(this.viewJobdetails[0].category == this.categories[i]){
  //    this.editcategory = this.categories[i];
  //   }
  //  }
  })
  
  }
  skillValues(data){
    this.categoryId = data;
    this.skills =[];
    var json = {
      "json": {
          "request": {
              "servicetype": "8",
              "functiontype": "8007",
               "session":{
          "sessionid":localStorage.getItem("session")
            },
              "data": {
                  "domain": data,
              }
          }
  
      }
  }
  
  this.http.get(this.url +'/JobzTop/Skill?request=' + JSON.stringify(json))
  .subscribe(
  (res: any) => {
  if(res.json.response.statusmessage == "Success"){
    this.skills = res.json.response.data.skill;
  }else if (res.json.response.statusmessage == "Invalid session") {
    this.showLoader=false;
      this.tran=false;
    this.ngFlashMessageService.showFlashMessage({
    // Array of messages each will be displayed in new line
    messages: ["Your session has  expired. Please logIn again!!"], 
    // Whether the flash can be dismissed by the user defaults to false
    dismissible: true, 
    // Time after which the flash disappears defaults to 2000ms
    timeout: 2000,
    // Type of flash message, it defaults to info and success, warning, danger types can also be used
    type: 'danger'
    });
    setTimeout(() => {
      this.router.navigate(['']);
    }, 2500);
    }
  
  })
  
  }

  constructor(private http:HttpClient, private sanitizer: DomSanitizer,private user:UserService, private datePipe:DatePipe, private fb:FormBuilder,private ngFlashMessageService: NgFlashMessageService,private router: Router) { }

  ngOnInit() {
  }

}
