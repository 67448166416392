import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../user.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-jobboard',
    templateUrl: './jobboard.component.html',
    styleUrls: ['./jobboard.component.css'],
    providers: [DatePipe]
})
export class JobboardComponent implements OnInit {

    jobDescriptionHtml: any;

    employeelist = [];
    companyNames: string;
    selectedCheckBox = false;
    specificUser = false;
    selectAll = true;
    AllSelectedId = [];
    id = "";
    mandatorySkills = [];
    preferableSkills = [];
    skills = [];
    levelsofarray = [];
    arrayvalues = [];
    arrayValues1 = [];
    navtab = 1;
    appliedId = "";
    arrayvalues2 = [];
    mandatory = "";
    level = "";
    exist = false;
    showLoader = false;
    pages = [];
    page = [];
    currentPage = 1;
    numPerPage = 12;
    maxSize = 5;
    assignId = "";
    prefSkills = [];
    imageshowRec = "";
    jobsId = "";
    countries = [];
    arrayValuesSkill = [];
    arrayValuesSkill1 = [];
    questionsList = [];
    assignquestionId = "";
    showAllJobs = true;
    showPostJob = true;
    errorMessage;
    pagination;
    tab;
    url = localStorage.getItem("url");
    constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private user: UserService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.listingJobs();
        this.countryDisplay("");
        this.industryList("");
    }

    search() {
        this.currentPage = 1;
    }

    ResetJobs() {
        this.searchEmpname = "";
        this.searchCompany = "";
        this.searchTitle = "";
        this.searchLocation = "";
        // this.searchSkills = "";

        this.searchApproveEmpName = "";
        this.searchApproveCompany = "";
        this.searchApproveTitle = "";
        this.searchApproveLocation = "";
        // this.searchApproveSkills = "";

        this.searchDisabledEmpName = "";
        this.searchDisabledCompany = "";
        this.searchDisabledTitle = "";
        this.searchDisabledLocation = "";
        // this.searchDisabledSkills = "";

        if (this.tab == 1) {
            this.listingJobs();
        } else if (this.tab == 2) {
            this.listingApprovedJobs();
        } else {
            this.listingDisabledJobs();
        }
    }

    changeNavTab(id) {
        ;
        this.navtab = id;
    };
    searchEmpname;
    searchSkills;
    searchCompany;
    searchTitle;
    searchLocation;

    trimedEmpname;
    trimedCompany;
    trimedTitle;
    trimedLocation;
    trimedSkill;
    itemsPerPage:number=10;
    totalItems:number=0;
    listingJobs() {
        this.tab = 1;
        this.showLoader = true;

        if (this.searchEmpname !== undefined) {
            this.trimedEmpname = this.searchEmpname.trim();
        }

        if (this.searchCompany !== undefined) {
            this.trimedCompany = this.searchCompany.trim();
        }

        if (this.searchTitle !== undefined) {
            this.trimedTitle = this.searchTitle.trim();
        }

        if (this.searchLocation !== undefined) {
            this.trimedLocation = this.searchLocation.trim();
        }

        if (skill !== undefined) {
            this.trimedSkill = skill.trim();
        }

        var skill = "";
        if (this.searchSkills != undefined) {
            skill = this.searchSkills;
            skill = encodeURIComponent(skill);
        } else {
            skill = undefined;
        }
        this.employeelist = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2012",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
                        "limit": this.itemsPerPage
                    },

                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "employerName": this.trimedEmpname,
                        "companyName": this.trimedCompany,
                        "jobTitle": this.trimedTitle,
                        "location": this.trimedLocation,
                        "skill": this.trimedSkill,
                        "approved": 0,
                        "offset": this.pagination
                        /*"Reject":reject*/

                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == "20051") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    } else if (res.json.response.statuscode == 0) {
                        if (res.json.response.data.length > 0) {

                            this.searchApproveEmpName = "";
                            // this.searchApproveSkills = "";
                            this.searchApproveCompany = "";
                            this.searchApproveTitle = "";
                            this.searchApproveLocation = "";

                            this.searchDisabledEmpName = "";
                            // this.searchDisabledSkills = "";
                            this.searchDisabledCompany = "";
                            this.searchDisabledTitle = "";
                            this.searchDisabledLocation = "";

                            this.showLoader = false;
                            this.employeelist = [];
                            this.employeelist = res.json.response.data;
                            this.totalItems = res.json.response.data.count;
                            this.companyName = unescape(decodeURIComponent(res.json.response.data[1].companyName));
                            /*  this.count = res.json.response.count;*/
                        }
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    count;
    searchApproveEmpName;
    searchApproveSkills;
    searchApproveCompany;
    searchApproveTitle;
    searchApproveLocation;
    trimmedApproveEmpname;
    trimmedApproveCompany;
    trimmedApproveTitle;
    trimmedApproveLocation;
    trimmedApproveSkill;
    listingApprovedJobs() {
        this.tab = 2;
        this.showLoader = true;

        if (this.searchApproveEmpName !== undefined) {
            this.trimmedApproveEmpname = this.searchApproveEmpName.trim();
        }

        if (this.searchApproveCompany !== undefined) {
            this.trimmedApproveCompany = this.searchApproveCompany.trim();
        }

        if (this.searchApproveTitle !== undefined) {
            this.trimmedApproveTitle = this.searchApproveTitle.trim();
        }

        if (this.searchApproveLocation !== undefined) {
            this.trimmedApproveLocation = this.searchApproveLocation.trim();
        }

        if (ApprovedSkill !== undefined) {
            this.trimedSkill = ApprovedSkill.trim();
        }

        var ApprovedSkill = "";
        if (this.searchApproveSkills != undefined) {
            ApprovedSkill = this.searchApproveSkills;
            ApprovedSkill = encodeURIComponent(ApprovedSkill);
        } else {
            ApprovedSkill = undefined;
        }
        this.employeelist = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2012",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "employerName": this.trimmedApproveEmpname,
                        "companyName": this.trimmedApproveCompany,
                        "jobTitle": this.trimmedApproveTitle,
                        "location": this.trimmedApproveLocation,
                        "skill": this.trimmedApproveSkill,
                        "approved": 1,
                        "offset": this.pagination
                        /*"Reject":reject*/

                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No jobs found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    } else if (res.json.response.statuscode == 0) {
                        if (res.json.response.data.length > 0) {
                            this.searchEmpname = "";
                            // this.searchSkills = "";
                            this.searchCompany = "";
                            this.searchTitle = "";
                            this.searchLocation = "";

                            this.searchDisabledEmpName = "";
                            // this.searchDisabledSkills = "";
                            this.searchDisabledCompany = "";
                            this.searchDisabledTitle = "";
                            this.searchDisabledLocation = "";

                            this.employeelist = [];
                            this.showLoader = false;
                            this.employeelist = res.json.response.data;
                            this.count = res.json.response.count;
                        }
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    }
                });
    };

    searchDisabledEmpName;
    searchDisabledSkills;
    searchDisabledCompany;
    searchDisabledTitle;
    searchDisabledLocation;
    trimmedDisabledEmpname;
    trimmedDisabledCompany;
    trimmedDisabledTitle;
    trimmedDisabledLocation;
    trimedDisabledSkill;
    listingDisabledJobs() {
        this.tab = 3;
        this.showLoader = true;

        if (this.searchDisabledEmpName !== undefined) {
            this.trimmedDisabledEmpname = this.searchDisabledEmpName.trim();
        }

        if (this.searchDisabledCompany !== undefined) {
            this.trimmedDisabledCompany = this.searchDisabledCompany.trim();
        }

        if (this.searchDisabledTitle !== undefined) {
            this.trimmedDisabledTitle = this.searchDisabledTitle.trim();
        }

        if (this.searchDisabledLocation !== undefined) {
            this.trimmedDisabledLocation = this.searchDisabledLocation.trim();
        }

        if (DisableSkill !== undefined) {
            this.trimedDisabledSkill = DisableSkill.trim();
        }

        var DisableSkill = "";
        if (this.searchDisabledSkills != undefined) {
            DisableSkill = this.searchDisabledSkills;
            DisableSkill = encodeURIComponent(DisableSkill);
        } else {
            DisableSkill = undefined;
        }
        this.employeelist = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2012",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
                        "limit": this.itemsPerPage
                    },
                    "data": {
                        "employerName": this.trimmedDisabledEmpname,
                        "companyName": this.trimmedDisabledCompany,
                        "jobTitle": this.trimmedDisabledTitle,
                        "location": this.trimmedDisabledLocation,
                        "skill": this.trimedDisabledSkill,
                        "approved": -1,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statusmessage == "No jobs found") {
                        this.showLoader = false;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {

                        this.searchEmpname = "";
                        // this.searchSkills = "";
                        this.searchCompany = "";
                        this.searchTitle = "";
                        this.searchLocation = "";

                        // this.searchApproveSkills = "";
                        this.searchApproveCompany = "";
                        this.searchApproveTitle = "";
                        this.searchApproveLocation = "";
                        this.searchApproveEmpName = "";

                        this.employeelist = [];
                        this.showLoader = false;
                        this.employeelist = res.json.response.data;
                        this.totalItems = res.json.response.data.count
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    decode(str: string) {
        if (str != undefined) {
            let obj = unescape(str.replace(/[+]/g, "%20"))
            return obj;
        }
    }

    convertDate(date) {
        return new Date(date);
    }

    displayView;
    displayEditJob;
    jdViewModal;
    data;
    jobView = [];
    newDate; keyskills;
    skillLevel; jobId; jobTitlenew;
    statenew; citynew; numberofpositionsnew;
    experiencelevelnew; link; jobskillsarray = [];
    countrynew;
    viewJob(jobId) {
        /*this.viewlistvalues = [];*/
        this.jdViewModal = "";
        this.mandatorySkills = [];
        this.prefSkills = [];
        ;
        this.id = jobId.jobId;
        var json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "jobId": this.id
                    }
                }
            }
        };

        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Job?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        // this.data = res.json.response.data;
                        this.jobView = res.json.response.data;
                        // for (var i = 0; i < this.data.length; i++) {
                        //     this.newDate = new Date(this.data[i].createdTime);
                        //     // this.date = $filter('date')(this.newDate, "dd MMM yyyy");
                        // }
                        this.displayView = 'block';
                        this.keyskills = res.json.response.data.keySkill;
                        if (this.keyskills.length > 0) {
                            for (var i = 0; i < this.keyskills.length; i++) {
                                if (this.keyskills[i].mandatory == true) {
                                    this.mandatorySkills.push(this.keyskills[i].skillName + "(" + this.keyskills[i].level + ")");
                                } else if (this.keyskills[i].preferable == true) {
                                    this.prefSkills.push(this.keyskills[i].skillName + "(" + this.keyskills[i].level + ")");
                                }
                            }
                            this.skillLevel = this.mandatorySkills + "";
                            this.skillLevel = this.skillLevel.substr(0, this.skillLevel.length - 1);
                        }
                        this.jobId = res.json.response.data.jobId;
                        // this.jobTitlenew = unescape(decodeURIComponent(this.data.jobTitle));
                        // this.countrynew = this.data.location.country;
                        // this.statenew = this.data.location.state;
                        // this.citynew = this.data.location.city;
                        // this.numberofpositionsnew = this.data.numberOfPositions;
                        // this.experiencelevelnew = this.data.experienceLevel;
                        // this.link = this.data.link;
                        // this.jobskillsarray = [];
                        // this.jobskillsarray = this.data.keySkill.skillName;
                        // this.jobskillsarray = this.data.keySkill;
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    OnClose() {
        ;
        this.displayView = 'none';
        this.displayEditJob = 'none';
    }

    getRFile(pid) {
        this.imageshowRec = "";
        ;
        // fileUpload.getCFile(pid).then(function (res) {
        //     this.imageshowRec = res;
        // });
    }

    employerDetails = [];
    employerId;
    email;
    name; website; companyName1; companyName; address1;
    address; photoId;
    viewEmpDetails(employerId) {
        /*this.viewlistvalues = [];*/
        this.imageshowRec = "";
        ;
        this.id = employerId;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2015",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        /* "employerAdminId":$cookieStore.get("this.employid"),*/
                        "employerId": employerId
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.employerDetails = res.json.response.employerDetails;
                        this.display = 'block';
                        // this.employerId = this.employerDetails.employerId;
                        // this.email = this.employerDetails.emailId;
                        // this.name = this.employerDetails.name;
                        // this.website = this.employerDetails.website;
                        // this.companyName1 = this.employerDetails.companyName;
                        if (this.companyName == undefined) {
                            this.companyName = "";
                        } else {
                            this.companyName = decodeURIComponent(unescape(this.companyName1));
                        }
                        //this.address1 = this.employerDetails.address;
                        if (this.address == undefined) {
                            this.address = "";
                        } else {
                            this.address = unescape(decodeURIComponent(this.address1));
                        }
                        //this.photoId = this.employerDetails.photoId;
                        this.getRFile(this.photoId);
                        //$('#viewEmpModal').modal('show');
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    display;
    OnCloseHandled() {
        this.imageshowRec = "";
        this.display = 'none';
        //$('#viewEmpModal').modal('hide');
    }

    approveJobs(item) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2013",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        /*"adminId": $cookieStore.get('adminId'),*/
                        "approved": 1,
                        "jobId": [
                            item.jobId
                        ],
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.listingJobs();
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listingJobs();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Approved successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });

                        }
                    }


                });
    };


    displaydeletequestion;
    deletePopup(jobId) {
        ;
        this.assignId = jobId.jobId;
        this.displaydeletequestion = 'block';
        //$('#deletepopup').modal('show');
        this.disableReason = ""
    }

    deleteQuestionPopup(questionId) {
        this.displaydeletequestion = 'none';
        // this.assignquestionId = questionId;
        // $('#deletequestionpopup').modal('show');
        // $('#questionPopupModal').modal('hide');

    }
    deletereason;
    deleteQuestion() {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2062",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "questionId": this.assignId,
                        "reason": this.deletereason,
                        "disabled": true
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.listingJobs();
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            // $('#deletequestionpopup').modal('hide');
                            // $('#questionPopupModal').modal('show');
                            this.listingJobs();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Disabled successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                            this.displaydeletequestion = 'none';
                            this.listingJobs();
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    enableQuestion(questionId) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2062",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "questionId": questionId,

                        "disabled": false
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listOfQuestions("");
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    questionPopup(jobId) {
        ;
        this.jobsId = jobId;
        //$('#questionPopupModal').modal('show');

    }

    listOfQuestions(item) {
        ;
        this.questionsList = [];
        this.jobsId = item.jobId;
        localStorage.setItem("id", this.jobsId);
        var json =
        {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2061",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "jobId": localStorage.getItem("id")
                    }
                }
            }
        }
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        //$('#questionPopupModal').modal('show');
                        this.questionsList = res.json.response.data.questions;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    }

    disableReason;
    rejectJobs() {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2013",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        /*"adminId": $cookieStore.get('adminId'),*/
                        "approved": -1,
                        "jobId": [
                            this.assignId
                        ],
                        "reason": this.disableReason,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listingJobs();
                            //$('#deletepopup').modal('hide');
                            this.displaydeletequestion = 'none';
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Disabled successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    selectedAllCheckBox;
    unapproveJobs(item) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2013",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        /* "adminId": $cookieStore.get('adminId'),*/
                        "approved": 0,
                        "jobId": [
                            item.jobId
                        ],
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listingJobs();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Unapproved successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else if (res.json.response.statuscode == 50097) {


                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });

                        }
                    }


                });
    };

    pushAllSelectedId() {
        ;
        if ((this.selectedAllCheckBox == false) || (this.selectedAllCheckBox == undefined)) {
            this.selectedCheckBox = false;
        }
        if ((this.selectedAllCheckBox == true) || (this.selectedAllCheckBox == undefined)) {
            this.selectedCheckBox = true;
            for (var i = 0; i < this.employeelist.length; i++) {
                this.AllSelectedId.push(this.employeelist[i].jobId);
                this.specificUser = false;
                this.selectAll = true;

            }
        } else {

            this.specificUser = true;
            this.selectAll = false;
        }
    };


    pushThisId(jobId) {
        ;
        this.AllSelectedId.push(jobId);
    }

    calllistJob() {
        //$location.path('/listJobs');
    };

    getjobs(jobId, value, id) {
        ;
        this.navtab = id;
        this.appliedId = jobId;
        // $cookieStore.put('jobid', this.appliedId);
        // $cookieStore.put('joblist', value);
        // $location.path('/matchingCandidates');
    }

    approve;
    commonSearch() {
        ;
        if (this.approve == 0) {
            this.listingJobs();

        } else if (this.approve == 1) {
            this.listingApprovedJobs();
        } else if (this.approve == -1) {
            this.listingDisabledJobs();
        }
    }


    countryDisplay(data) {

        var countryJson = {
            "json": {
                "request": {
                    "servicetype": "100",
                    "functiontype": "101",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": 0,
                        "limit": 10
                    },
                    /* "country": "_ALL_"*/
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(countryJson));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {
                                var country = res.json.response.data;
                                for (var i = 0; i < country.length; i++) {
                                    this.countries.push(country[i].countryname);
                                }

                            }
                        } else if (res.json.response.statuscode == 50097) {


                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });


    };

    states = [];
    cities = [];
    stateDisplay(item) {
        if (item == undefined) {
            this.countrynew = "";
            this.statenew = "";
            this.citynew = "";
            this.states = [];
            this.cities = [];
        }
        var countryJson = {
            "json": {
                "request": {

                    "servicetype": "100",
                    "functiontype": "102",
                    /* "country": "_ALL_"*/

                    "data": {
                        "countryname": item
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(countryJson));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {

                                var state = res.json.response.data;
                                this.states = [];
                                for (var i = 0; i < state.length; i++) {
                                    this.states.push(state[i].statename);
                                }

                            }
                        } else if (res.json.response.statuscode == 50097) {


                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    cityDisplay(state, country) {

        if (state == undefined) {
            this.statenew = "";
            this.citynew = "";
            this.states = [];
            this.cities = [];
        }

        if (country == undefined) {
            this.countrynew = "";
            this.statenew = "";
            this.citynew = "";
            this.states = [];
            this.cities = [];
        }
        var cityJson = {
            "json": {
                "request": {
                    "servicetype": "100",
                    "functiontype": "103",
                    "data": {
                        "countryname": country,
                        "statename": state
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(cityJson));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {



                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            type: 'danger'
                        });
                        window.scrollTo(500, 0);
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {


                                var city = res.json.response.data;
                                this.cities = [];
                                for (var i = 0; i < city.length; i++) {
                                    this.cities.push(city[i].cityname);
                                }

                            }
                        } else if (res.json.response.statuscode == 50097) {


                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };
    industries;
    industryList(item) {

        var industryJson = {
            'json': {
                'request': {
                    'servicetype': '21',
                    'functiontype': '1014',
                    "industry": '_ALL_'
                },"session": {
                    "sessionid": localStorage.getItem('sessionId')
                },  
                 "records":{
                    "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
                    "limit": this.itemsPerPage
                }
            }
        };

        let temp = encodeURIComponent(JSON.stringify(industryJson));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i, el) {
                            //         if ($.inArray(el, uniqueNames) === -1) {
                            //             uniqueNames.push(el);
                            //             finalNames.push({
                            //                 id: el,
                            //                 name: el
                            //             });
                            //         }
                            //     });
                            //     this.industries = finalNames;
                            // }
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });

    }

    newentry; categories;
    categoryList(item) {
        this.newentry = item;
        var categoryJson = {
            'json': {
                'request': {
                    'servicetype': '21',
                    'functiontype': '1014',
                    "industry": this.newentry,
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(categoryJson));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];

                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i, el) {
                            //         if ($.inArray(el, uniqueNames) === -1) {
                            //             uniqueNames.push(el);
                            //             finalNames.push({
                            //                 id: el,
                            //                 name: el
                            //             });
                            //         }
                            //     });
                            //     this.categories = finalNames;
                            // }
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    }

    manOrPre; showSkillTable;
    showSkillTable1;
    jobTitle; jobTypenew; country; state; assessmentReq; newIndustry; currentIndIdx;
    city; newCategory; numberOfPositions; jobDescriptionnew; jobExperience1; jobDescription; keySkills;
    editJob(item) {
        ;
        this.arrayValuesSkill = [];
        this.arrayValuesSkill1 = [];
        /*  this.viewlistvalues = [];*/
        this.arrayvalues2 = [];
        this.arrayvalues = [];
        if (item.jobId == undefined) {
            this.id = item;
        } else {
            this.id = item.jobId;
        }
        /* this.showLoader = true;*/
        var json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5003",
                    "data": {
                        "jobId": item.jobId,
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Job?request=' + temp)
            .subscribe(
                (res: any) => {


                    if (res.success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            //$('#editJobModal').modal('show');
                            this.displayEditJob = 'block';
                            this.data = res.json.response.data;
                            this.keyskills = this.data.keySkill;
                            this.manOrPre = "";
                            this.arrayValues1 = [];
                            this.arrayValuesSkill = [];
                            this.arrayValuesSkill1 = [];

                            this.arrayvalues2 = [];
                            this.arrayvalues = [];
                            for (var i = 0; i < this.keyskills.length; i++) {

                                if (this.keyskills[i].mandatory == true) {
                                    this.manOrPre = "Mandatory";
                                    this.showSkillTable = true;

                                    this.arrayValuesSkill.push({
                                        "skillName": unescape(decodeURIComponent(this.keyskills[i].skillName)),
                                        "level": this.keyskills[i].level,
                                        "manOrPre": this.manOrPre
                                    });
                                } else {
                                    this.manOrPre = "Preferred";
                                    this.showSkillTable1 = true;
                                    this.arrayValuesSkill1.push({
                                        "skillName": unescape(decodeURIComponent(this.keyskills[i].skillName)),
                                        "level": this.keyskills[i].level,
                                        "manOrPre": this.manOrPre
                                    });
                                }
                                this.arrayvalues.push({
                                    "skillName": escape(encodeURIComponent(this.keyskills[i].skillName)),
                                    "level": this.keyskills[i].level,
                                    "manOrPre": this.manOrPre
                                });

                            }
                            this.jobTitle = unescape(decodeURIComponent(res.json.response.data.jobTitle));
                            this.jobTypenew = res.json.response.data.type;
                            this.country = res.json.response.data.location.country;
                            this.stateDisplay(this.country);
                            this.state = res.json.response.data.location.state;
                            this.cityDisplay("", "");
                            this.city = res.json.response.data.location.city;
                            this.jobExperience1 = res.json.response.data.experienceLevel;
                            this.numberOfPositions = res.json.response.data.numberOfPositions;
                            this.keySkills = res.json.response.data.keySkill.skillName
                            this.newCategory = res.json.response.data.category;
                            this.jobDescriptionnew = res.json.response.data.jobDescription;
                            this.jobDescriptionHtml = decodeURIComponent(res.json.response.data.jobDescription);

                            this.assessmentReq = res.json.response.data.assessment;
                            if (res.json.response.data.industry.name == undefined) {
                                this.newIndustry = res.json.response.data.industry;
                                this.currentIndIdx = this.industries.findIndex(ind => ind.name === this.newIndustry);
                            } else {
                                this.newIndustry = res.json.response.data.industry.name;
                                this.currentIndIdx = this.industries.findIndex(ind => ind.name === this.newIndustry);
                            }

                            this.categoryList(this.newIndustry);
                            if (this.newCategory != undefined) {
                                // this.skillsforJob(this.newCategory);
                                // this.skillsforJob1(this.newCategory);
                            }
                            this.numberOfPositions = parseInt(res.json.response.data.numberOfPositions);
                            this.jobDescription = unescape(decodeURIComponent(this.jobDescriptionnew));
                        } else if (res.json.response.statuscode == 50097) {
                            this.errorMessage = res.json.response.statusmessage;
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    discardChangesOnEdit() {
        this.editJob(this.id)
    }
    levelnew;
    multipleskills(data, forMand) {
        ;
        this.arrayvalues2 = [];

        var mandatory = false;
        var prefferable = false;
        var manOrPre = "";

        if (forMand == "yes") {
            mandatory = true;
            prefferable = false;
            manOrPre = "Mandatory";
        } else {
            mandatory = false;
            prefferable = true;
            manOrPre = "Preferred";
        }
        this.exist = false;
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if ((escape(encodeURIComponent(data.skillName))) == this.arrayvalues[i].skillName) {
                this.exist = true;
            }
        }
        if (this.exist == true) {
            this.ngFlashMessageService.showFlashMessage({
                messages: ["This Skill is already added"],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
            });

        } else {
            if (this.arrayValuesSkill.length == 3) {
                this.ngFlashMessageService.showFlashMessage({
                    messages: ["Maximum skill limit reached"],
                    dismissible: true,
                    timeout: 2000,
                    type: 'danger'
                });
            } else {
                this.arrayvalues.push({
                    "skillName": (escape(encodeURIComponent(data.skillName))),
                    "level": this.levelnew,
                    "mandatory": mandatory,
                    "preferable": prefferable,
                    "manOrPre": manOrPre
                });

                this.arrayValuesSkill.push({
                    "skillName": data.skillName,
                    "level": this.levelnew,
                    "mandatory": mandatory,
                    "preferable": prefferable,
                    "manOrPre": manOrPre
                });
                for (var i = 0; i < this.arrayvalues.length; i++) {
                    if (this.arrayvalues[i].manOrPre == "Preferred") {
                        this.arrayvalues2.push({
                            "skillName": this.arrayvalues[i].skillName,
                            "level": this.arrayvalues[i].level,
                            "mandatory": false,
                            "preferable": true,
                        })

                    } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                        this.arrayvalues2.push({
                            "skillName": this.arrayvalues[i].skillName,
                            "level": this.arrayvalues[i].level,
                            "mandatory": true,
                            "preferable": false,
                        })
                    }

                }


            }
        }


        this.clearValues();
    };

    levelnew1;
    multipleskills1(data, forMand) {
        ;
        this.arrayvalues2 = [];

        var mandatory = false;
        var prefferable = false;
        var manOrPre = "";

        if (forMand == "yes") {
            mandatory = true;
            prefferable = false;
            manOrPre = "Mandatory";
        } else {
            mandatory = false;
            prefferable = true;
            manOrPre = "Preferred";
        }

        this.exist = false;
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if ((escape(encodeURIComponent(data))) == this.arrayvalues[i].skillName) {
                this.exist = true;
            }
        }

        if (this.exist == true) {
            this.ngFlashMessageService.showFlashMessage({
                messages: ["This Skill is already added"],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
            });

        } else {

            this.arrayvalues.push({
                "skillName": (escape(encodeURIComponent(data))),
                "level": this.levelnew1,
                "mandatory": mandatory,
                "preferable": prefferable,
                "manOrPre": manOrPre
            });
            this.arrayValuesSkill1.push({
                "skillName": data,
                "level": this.levelnew1,
                "mandatory": mandatory,
                "preferable": prefferable,
                "manOrPre": manOrPre
            });

            for (var i = 0; i < this.arrayvalues.length; i++) {

                if (this.arrayvalues[i].manOrPre == "Preferred") {
                    this.arrayvalues2.push({
                        "skillName": this.arrayvalues[i].skillName,
                        "level": this.arrayvalues[i].level,
                        "mandatory": false,
                        "preferable": true,
                    })

                } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                    this.arrayvalues2.push({
                        "skillName": this.arrayvalues[i].skillName,
                        "level": this.arrayvalues[i].level,
                        "mandatory": true,
                        "preferable": false,
                    })
                }
            }
        }
        this.clearValues1();
    };

    skillsnew;
    clearValues() {
        this.skillsnew = "";
    }
    skillsnew1;
    clearValues1() {
        this.showSkillTable1 = true;
        this.skillsnew1 = "";
    }

    deleteItem(data) {
        ;
        this.arrayvalues2 = [];

        this.levelsofarray.push(data.skillName);
        var index = this.arrayvalues.findIndex(skill => skill.skillName === data.skillName);

        if (index != -1) {
            this.arrayvalues.splice(index, 1);
        }

        var index1 = this.arrayValuesSkill.indexOf(data);
        if (index1 != -1) {

            this.arrayValuesSkill.splice(index1, 1);
        }
        if (this.arrayValuesSkill.length == 0) {
            this.showSkillTable = false;
        }
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if (this.arrayvalues[i].manOrPre == "Preferred") {
                this.arrayvalues2.push({
                    "skillName": escape(encodeURIComponent((this.arrayvalues[i].skillName))),
                    "level": this.arrayvalues[i].level,
                    "mandatory": false,
                    "preferable": true,
                })

            } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                this.arrayvalues2.push({
                    "skillName": escape(encodeURIComponent((this.arrayvalues[i].skillName))),
                    "level": this.arrayvalues[i].level,
                    "mandatory": true,
                    "preferable": false,
                })
            }

        }

    };

    deleteItem1(data) {
        ;
        this.arrayvalues2 = [];

        this.levelsofarray.push(data.skillName);
        var index = this.arrayvalues.findIndex(skill => skill.skillName === data.skillName);

        if (index != -1) {
            this.arrayvalues.splice(index, 1);
        }

        var index1 = this.arrayValuesSkill1.indexOf(data);
        if (index1 != -1) {

            this.arrayValuesSkill1.splice(index1, 1);
        }
        if (this.arrayValuesSkill1.length == 0) {
            this.showSkillTable = false;
        }
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if (this.arrayvalues[i].manOrPre == "Preferred") {
                this.arrayvalues2.push({
                    "skillName": escape(encodeURIComponent((this.arrayvalues[i].skillName))),
                    "level": this.arrayvalues[i].level,
                    "mandatory": false,
                    "preferable": true,
                })

            } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                this.arrayvalues2.push({
                    "skillName": escape(encodeURIComponent((this.arrayvalues[i].skillName))),
                    "level": this.arrayvalues[i].level,
                    "mandatory": true,
                    "preferable": false,
                })
            }
        }

    };

    updateDetails() {
        ;

        // if (this.newCategory.name == undefined) {
        //     this.newCategory = this.newCategory;
        // } else {
        //     this.newCategory = this.newCategory.name;
        // }
        // if (this.newIndustry.name == undefined) {
        //     this.newIndustry = this.newIndustry;
        // } else {
        //     this.newIndustry = this.newIndustry.name;
        // }
        var json =
        {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5002",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "jobId": this.id,
                        /*"employerId": $cookieStore.get("this.employid"),*/
                        "jobTitle": (escape(encodeURIComponent(this.jobTitle))),
                        "jobDescription": escape((encodeURIComponent(this.jobDescription))),
                        "numberOfPositions": this.numberOfPositions,
                        /*  "location": this.city,*/
                        "location": {
                            "country": this.country,
                            "state": this.state,
                            "city": this.city
                        },
                        "experienceLevel": this.jobExperience1,
                        "keySkills": this.arrayvalues2,
                        "industry": this.newIndustry,
                        "category": this.newCategory,
                        "assessment": this.assessmentReq
                        /*"subcategory":this.newSubCategory*/
                    }
                }
            }
        }
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Updated Successfully!!"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'Success'
                        });
                        if (this.approve == 0) {
                            this.listingJobs();
                        } else if (this.approve == 1) {
                            this.listingApprovedJobs();
                        } else if (this.approve == -1) {
                            this.listingDisabledJobs();
                        }

                        //$('#editJobModal').modal('hide');




                    } else if (res.json.response.statuscode == 50097) {


                        this.errorMessage = res.json.response.statusmessage;
                    } else {
                        //$('#editJobModal').scrollTop(0);
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    }


                });


    }
    skillList;
    skillsforJob(item) {

        this.newentry = item;
        if (this.newentry.length < 3) {
            this.newentry = undefined;
        }

        var json = {
            "json": {
                "request": {
                    "servicetype": "8",
                    "functiontype": "8007",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    /* "sort":{
                         "sortby":"title",
                         "orderby":"ASC"
                     },*/
                    "data": {
                        "domain": this.newentry
                    }
                }

            }
        };

        if (this.newentry != undefined) {
            this.skillList(json).then(function (res) {


                if (res.success !== undefined) {
                    this.noSkillError = false;
                    this.ngFlashMessageService.showFlashMessage({
                        messages: [res.json.response.statusmessage],
                        dismissible: true,
                        timeout: 2000,
                        type: 'danger'
                    });
                } else {

                    if (res.json.response.statuscode == 0) {
                        /* this.skills = "";*/

                        var uniqueNames1 = [];
                        var finalNames1 = [];
                        if (res.json.response.data) {
                            this.noSkillErrorMsg = "";

                            this.noSkillError = false;
                            this.skills = res.json.response.data.skill;
                        }
                    } else if (res.json.response.statuscode == 20038) {
                        this.noSkillError = true;
                        this.noSkillErrorMsg = res.json.response.statusmessage;
                    } else {
                        this.noSkillError = false;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                }

            });

        }


    };

    skillsforJob1(item) {
        ;

        if (item.name == undefined) {
            this.newentry = item;
        } else {
            this.newentry = item.name;

        }
        if (this.newentry.length < 3) {
            this.newentry = undefined;
        }

        var json = {
            "json": {
                "request": {
                    "servicetype": "8",
                    "functiontype": "8007",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    /* "sort":{
                         "sortby":"title",
                         "orderby":"ASC"
                     },*/
                    "data": {
                        "domain": this.newentry,
                        "interviewable": false
                    }
                }

            }
        };

        if (this.newentry != undefined) {
            this.skillList(json).then(function (res) {


                if (res.success !== undefined) {
                    this.noSkillError = false;
                    this.ngFlashMessageService.showFlashMessage({
                        messages: [res.json.response.statusmessage],
                        dismissible: true,
                        timeout: 2000,
                        type: 'danger'
                    });
                } else {

                    if (res.json.response.statuscode == 0) {
                        /* this.skills = "";*/

                        var uniqueNames1 = [];
                        var finalNames1 = [];
                        if (res.json.response.data) {
                            this.noSkillErrorMsg = "";

                            this.noSkillError = false;
                            this.skills1 = res.json.response.data.skill;
                        }
                    } else if (res.json.response.statuscode == 20038) {
                        this.noSkillError = true;
                        this.noSkillErrorMsg = res.json.response.statusmessage;
                    } else {
                        this.noSkillError = false;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                }

            });

        }


    };

    skillsforSearch(data) {
        /*  ;*/
        var json = {
            "json": {
                "request": {
                    "servicetype": "5",
                    "functiontype": "5005"
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json));

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {


                    if (res.success !== undefined) {

                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {

                        if (res.json.response.statuscode == 0) {

                            var uniqueNames1 = [];
                            var finalNames1 = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i1, el1) {
                            //         if (el1 !== "") {
                            //             if ($.inArray(el1, uniqueNames1) === -1) {
                            //                 uniqueNames1.push(el1);
                            //                 finalNames1.push({
                            //                     name: el1
                            //                 });
                            //             }
                            //         }
                            //     });
                            //     this.skills = finalNames1;
                            // }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }

                });
    };
    //end skillsforjobs
    skillId;
    searchSkill;
    multipleskillsforsearch(item) {
        ;
        this.skillId = item.name;

        this.searchSkill = item.name;

    }

}
