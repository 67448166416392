import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpModule} from '@angular/http';
import { HttpClientModule} from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgFlashMessagesModule } from 'ng-flash-messages';
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule } from '@angular/material';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg'; 


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DomainComponent } from './domain/domain.component';
import { JobboardComponent } from './jobboard/jobboard.component';
import { CandidatesComponent } from './candidates/candidates.component';
import { EmployersComponent } from './employers/employers.component';
import { CompaniesComponent } from './companies/companies.component';
import { PlanmanagementComponent } from './planmanagement/planmanagement.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { AssessorsComponent } from './assessors/assessors.component';
import { InterviewsComponent } from './interviews/interviews.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AddPanelComponent } from './add-panel/add-panel.component';
import {UserService} from './user.service';
import { PostJobComponent } from './post-job/post-job.component';
import { SkillsComponent } from './skills/skills.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

const appRoutes: Routes = [
  { path: '', 
  component: LoginComponent },
  { path: 'Dashboard',
   component: DashboardComponent },
   { path: 'Domain',
   component: DomainComponent },
   { path: 'JobBoard',
   component: JobboardComponent },
   { path: 'Candidates',
   component: CandidatesComponent },
   { path: 'Employers',
   component: EmployersComponent },
   { path: 'Companies',
   component: CompaniesComponent },
   { path: 'Planmanagement',
   component: PlanmanagementComponent },
   { path: 'Schedule',
   component: SchedulesComponent },
   { path: 'Assessors',
   component: AssessorsComponent },
   { path: 'Interviews',
   component: InterviewsComponent },
   { path: 'Testimonial',
   component: TestimonialComponent },
   { path: 'AddPanel',
   component: AddPanelComponent },
   { path: 'skills',
   component: SkillsComponent },
   { path: 'postJob',
   component: PostJobComponent },
   { path: 'Configuration',
  //  canActivate : [AuthguardGuard],
   component: ConfigurationComponent },
];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DomainComponent,
    JobboardComponent,
    CandidatesComponent,
    EmployersComponent,
    CompaniesComponent,
    PlanmanagementComponent,
    SchedulesComponent,
    AssessorsComponent,
    InterviewsComponent,
    TestimonialComponent,
    ConfigurationComponent,
    MenuComponent,
    NavbarComponent,
    AddPanelComponent,
    PostJobComponent,
    SkillsComponent,
    

    //jqxChartComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule ,
    RouterModule.forRoot(appRoutes),
    HttpModule, 
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule, 
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    ChartsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
   
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgFlashMessagesModule.forRoot(),
    RouterModule.forRoot(appRoutes,{useHash:true}),
    NgxEditorModule,
    TooltipModule.forRoot(),
    //FroalaEditorModule.forRoot(),
    //FroalaViewModule.forRoot(),
  ],
  providers: [UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
