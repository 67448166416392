import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { UserService } from '../user.service';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-planmanagement',
  templateUrl: './planmanagement.component.html',
  styleUrls: ['./planmanagement.component.css'],
  providers: [DatePipe]
})
export class PlanmanagementComponent implements OnInit {

  oFrom: FormGroup;
  post: any;
  tittlealert: string = 'Enter the Value';


  constructor(private fb: FormBuilder, private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService) {
    this.oFrom = fb.group({
      'planName': [null, Validators.compose([Validators.required, Validators.pattern(/[0-9]/)])],
      'maxQuestion': [null, Validators.compose([Validators.required, Validators.pattern(/[0-9]/)])],
      'time': [null, Validators.compose([Validators.required, Validators.pattern(/[0-9]/)])],
    });
  }

  ngOnInit() {
    this.listingPlans();
  }

  searchStatus; showLoader;
  planList = [];
  pagination; count; json;
  errorMessage;
  url = localStorage.getItem("url");
  listingPlans() {
    if (this.searchStatus == "Approved") {
      this.searchStatus == "false";
    }
    else if (this.searchStatus == "Disabled") {
      this.searchStatus == "true";
    }
    this.showLoader = true;
    ;

    this.planList = [];
    if (this.searchStatus == "") {
      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2058",
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "data": {
              "offset": this.pagination
            }
          }
        }
      };

    } else {
      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2058",
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "data": {
              "offset": this.pagination,
              "disabled": this.searchStatus
            }
          }
        }
      };

    }
    let temp = encodeURIComponent(JSON.stringify(this.json));

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20051") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }

          else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.planList = res.json.response.data.list;
            this.count = res.json.response.count;
          }
          else if (res.json.response.statuscode == 50097) {


            this.errorMessage(res.json.response.statusmessage);
          }
          else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });

          }

        });
  };

  addPopup() {
    this.display1 = "block";
    //$('#addModal').modal('show');
  }
  addplanName;
  addmaximumQuestion: string = '';
  addmaximumTime: string = '';
  inputControl: FormControl = new FormControl('');

  addPlan() {
    /* $scope.id = id;*/
    this.addmaximumQuestion = this.inputControl.value
    this.addmaximumTime = this.inputControl.value

      ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2057",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {

            "name": this.addplanName,
            "maximumQuestion": this.addmaximumQuestion,
            "maximumTime": this.addmaximumTime
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json));

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            // this.addplanName = "";
            // this.addmaximumQuestion = "";
            // this.addmaximumTime = "";
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Added Successfully!!"],
              dismissible: true,
              timeout: 5000,
              type: 'success'
            });
            //$('#addModal').modal('hide');
            this.display1 = "none";

          } else if (res.json.response.statuscode == 50097) {


            this.errorMessage(res.json.response.statusmessage);
          } else {

            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
            //$('#editJobModal').scrollTop(0);
          }
        });
  }
  display;
  display1;
  editPlan = function (item) {
    this.planName = item.name;
    // this.maximumQuestion1 = item.maximumQuestion;
    this.maximumTime = item.maximumTime;
    this.planId = item.id;
    this.display = 'block';
  }
  OnCloseHandled() {
    this.display1 = 'none';
    this.display = 'none';
    this.oFrom.reset();
    //this.displayTime='none'
  }
  updateDetails = function () {
    /* $scope.id = id;*/
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2060",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.planId,
            "name": this.planName,
            "maximumQuestion": this.maximumQuestion1,
            "maximumTime": this.maximumTime
          }
        }
      }
    };

    let temp = encodeURIComponent(JSON.stringify(json));

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Updated Successfully!!"],
              dismissible: true,
              timeout: 5000,
              type: 'success'
            });
            this.display = 'none';
            this.oFrom.reset();
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
            //$('#editJobModal').scrollTop(0);
          }
        });
  }

  planId = "";
  displayDisable;
  deletePopup(planId) {
    ;
    this.planId = planId.id;
    //$('#disableModal').modal('show');
    this.displayDisable = 'block';
  }

  reason;
  disablePlan() {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2060",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /*"adminId": $cookieStore.get('adminId'),*/
            "disabled": true,
            "id": this.planId,
            "reason":this.reason
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json));

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingPlans();
              //$('#disableModal').modal('hide');
              this.displayDisable = 'none';
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Disabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
            } else if (res.json.response.statuscode == 20021) {
              this.errorMessage(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };
  convertDate(date) {
    return new Date(date);
  };
}
