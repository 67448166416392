import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css'],
  providers: [DatePipe]
})
export class SchedulesComponent implements OnInit {

  showAllJobs = true;
  showPostJob = true;
  url = localStorage.getItem("url");

  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService) { }

  ngOnInit() {
    this.listingSchedules("");
  }

  decode(str: string) {
    let obj = unescape(str.replace(/[+]/g, "%20"))
    return obj;
  }

  showLoader; searchStatus;
  scheduleList = [];
  listSchedules = [];
  queueId = []; json; errorMessage;
  searchAssName; searchCandName; searchSkill; searchCompName; dob; joiningdate;
  trimmedSearchAssName;
  trimmedSearchCandName;
  trimmedSearchSkill;
  trimmedSearchCompName;
  currentPage:number=1;
  itemsPerPage:number=10;
  totalItems:number=0;  
  listingSchedules(item) {

    if (this.searchAssName !== undefined) {
      this.trimmedSearchAssName = this.searchAssName.trim();
    }

    if (this.searchCandName !== undefined) {
      this.trimmedSearchCandName = this.searchCandName.trim();
    }

    if (this.searchSkill !== undefined) {
      this.trimmedSearchSkill = this.searchSkill.trim();
    }

    if (this.searchCompName !== undefined) {
      this.trimmedSearchCompName= this.searchCompName.trim();
    }

    this.showLoader = true;
    this.queueId = [];
    ;
    this.scheduleList = [];

    this.listSchedules = [];
    if (this.searchStatus == "") {

      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2052",
            "sort": {
              "sortby": "scheduledtime",
              "orderby": "DESC"

            },
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "records": {
              "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
              "limit": this.itemsPerPage
            },  
            "data": {
              "assessorName": this.trimmedSearchAssName,
              "candidateName": this.trimmedSearchCandName,
              "skill": this.trimmedSearchSkill,
              "companyName": this.trimmedSearchCompName,
              "startDate": this.dob,
              "endDate": this.joiningdate
            }
          }
        }
      };
    } else {
      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2052",
            "sort": {
              "sortby": "scheduledtime",
              "orderby": "DESC"

            },
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "records": {
              "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
              "limit": this.itemsPerPage
            },  
            "data": {
              "assessorName": this.trimmedSearchAssName,
              "candidateName": this.trimmedSearchCandName,
              "skill": this.trimmedSearchSkill,
              "status": this.searchStatus,
              "companyName": this.trimmedSearchCompName,
              "startDate": this.dob,
              "endDate": this.joiningdate
            }
          }
        }
      };
    }
    let temp = encodeURIComponent(JSON.stringify(this.json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20051") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode === "0") {
            this.showLoader = false;
            this.totalItems = res.json.response.data.count;
            this.scheduleList = res.json.response.data.scheduledList;
           

            // for (var i = 0; i < this.scheduleList.length; i++) {
            //     var date = new Date(this.scheduleList[i].scheduledTime);
            //     var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));

            //     this.queueId = this.scheduleList[i].queueId;

            //     var updateddate = $filter('date')(utcDate, "dd-MMM-yyyy HH:mm:ss");
            //     $scope.listSchedules.push({
            //         "queueId": $scope.scheduleList[i].queueId,
            //         "candName": $scope.scheduleList[i].candidateName,
            //         "assName": $scope.scheduleList[i].assessorName,
            //         "skill": $scope.scheduleList[i].skill,
            //         "scheduledTime": updateddate,
            //         "status": $scope.scheduleList[i].status,
            //         "assessorStatus": $scope.scheduleList[i].assessorStatus,
            //         "sponsored": $scope.scheduleList[i].sponsored,
            //         "companyName": $scope.scheduleList[i].companyName
            //     });
            // }
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  qId = ""
  openUpdateSchedule(item) {
    ;
    this.qId = item.queueId;
    localStorage.setItem('quId', this.qId);
    //$('#updateScheduleModal').modal('show');
  }

  dateTime = "";
  Time = "";
  updateSchedule(year, time) {
    ;
    this.Time = time + ":" + "00";
    this.dateTime = year + ' ' + this.Time;
    //$('#updateScheduleModal').modal('show');

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2065",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "queueId": localStorage.getItem('quId'),
            "scheduledTime": this.dateTime
          }
        }
      }
    }
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;

          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            //$('#updateScheduleModal').modal('hide');
            this.listingSchedules("");
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  }
}
