import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { Router, NavigationExtras } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';
@Component({
    selector: 'app-employers',
    templateUrl: './employers.component.html',
    styleUrls: ['./employers.component.css'],
    providers: [DatePipe]
})
export class EmployersComponent implements OnInit {

    errorMessage;
    industries;
    disable; enable; postjob; admin
    tab=1;
    url = localStorage.getItem("url");
    display1 = 'none';
    display2 = 'none';
    showMessage;

    constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private router: Router, private user: UserService) { }

    ngOnInit() {

        this.listofEmpRegisters()
        // this.industryList("")
    }

    currentPage:any = 1;
    currentTab:number = 1;
    itemsPerPage:number=10;
    totalItems:number=0;
    search() {
        this.currentPage = 1;
        this.totalItems = 0;
        this.listofEmpRegisters()
    }

    empPagination(tab){

        if(tab == 1){
            this.listofEmpRegisters()
        }else if(tab == 2){
            this.listofApprovedEmpRegisters()
        }else{
            this.listofDisabledEmpRegisters()
        }
    }

    industryList(item) {
        ;
        var industryJson = {
            'json': {
                'request': {
                    'servicetype': '21',
                    'functiontype': '1014',
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "industry": '_ALL_'
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(industryJson))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i, el) {
                            //         if ($.inArray(el, uniqueNames) === -1) {
                            //             uniqueNames.push(el);
                            //             finalNames.push({
                            //                 id: el,
                            //                 name: el
                            //             });
                            //         }
                            //     });
                            //     this.industries = finalNames;

                            // }
                        } else if (res.json.response.statuscode == 50097) {


                            this.errorMessage(res.json.response.statusmessage);
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });

    }
    showLoader;
    employeeRegList = [];
    searchCompany; searchDomain; searchEmail; pagination;
    trimmedSearchCompany;
    trimmedSearchEmail;
    listofEmpRegisters() { 
        this.tab = 1;
        
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }

        if (this.searchCompany !== undefined) {
            this.trimmedSearchCompany = this.searchCompany.trim();
        }

        if (this.searchEmail !== undefined) {
            this.trimmedSearchEmail = this.searchEmail.trim();
        }

        this.disable = true;
        this.enable = true;
        this.admin = false;
        this.postjob = false;
        ;
        this.showLoader = true;
        this.employeeRegList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2014",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": (this.currentPage - 1) * this.itemsPerPage, 
                        "limit":this.itemsPerPage
                    },

                    "data": {
                        /*"adminId": $cookieStore.get('adminId'),
                         */
                        "companyName": this.trimmedSearchCompany,
                        // "companyDomain": this.searchDomain,
                        "email": this.trimmedSearchEmail,
                        /* "name":this.searchName,*/
                        "approved": 0,
                        // "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == "20021") {
                        this.showLoader = false;
                        this.showMessage = true;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.employeeRegList = res.json.response.data;
                        this.showMessage = false;
                        this.totalItems = res.json.response.count;
                        console.log(res.json.response.data.count);

                        /* for(var i=0;this.employeelist.length;i++) {
              this.allJobIds.push(this.employeelist[i].jobId);
                     }*/
                        this.searchApproveEmailSearch = ""
                        this.searchDisableEmpEmail = ""
                        this.approveSearchCompany = ""
                        this.searchDisableEmpCompany = ""
                    } else {
                        this.totalItems = res.json.response.count;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }

                });
    };
    searchName;
    count;
    searchApproveEmailSearch;
    approveSearchCompany;
    trimmedSearchApproveCompany
    trimmedSearchApproveEmail

    
    listofApprovedEmpRegisters() {
        this.tab = 2;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.approveSearchCompany !== undefined) {
            this.trimmedSearchApproveCompany = this.approveSearchCompany.trim();
        }

        if (this.searchApproveEmailSearch !== undefined) {
            this.trimmedSearchApproveEmail = this.searchApproveEmailSearch.trim();
        }
        this.enable = false;
        this.disable = true;
        this.admin = true;
        this.postjob = true;

        ;
        this.showLoader = true;
        this.employeeRegList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2014",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": (this.currentPage - 1) * this.itemsPerPage, 
                        "limit":this.itemsPerPage
                    },
                    "data": {
                        /*"adminId": $cookieStore.get('adminId'),
                         */
                        "companyName": this.trimmedSearchApproveCompany,
                        // "companyDomain": this.searchDomain,
                        "email": this.trimmedSearchApproveEmail,
                        "name": this.searchName,
                        "approved": 1,
                        // "offset": this.pagination

                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == 20021) {
                        this.showLoader = false;
                        this.showMessage = true;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });

                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.employeeRegList = res.json.response.data;
                        this.totalItems = res.json.response.count;
                        this.count = res.json.response.count;
                        // this.currentPage =1 ;
                        this.showMessage = false;
                        /* for(var i=0;this.employeelist.length;i++) {
              this.allJobIds.push(this.employeelist[i].jobId);
                     }*/
                        this.searchEmail = ""
                        this.searchDisableEmpEmail = ""
                        this.searchCompany = ""
                        this.searchDisableEmpCompany = ""
        
                    } else {
                        this.totalItems = res.json.response.count;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    searchDisableEmpEmail;
    searchDisableEmpCompany;
    trimmedSearchDisabledCompany;
    trimmedSearchDisabledEmail;
    listofDisabledEmpRegisters() {    
        this.tab = 3;
        if (this.tab !== this.currentTab) {
            this.currentPage = 1;
            this.currentTab = this.tab;
        }
        if (this.approveSearchCompany !== undefined) {
            this.trimmedSearchDisabledCompany = this.searchDisableEmpCompany.trim();
        }

        if (this.searchApproveEmailSearch !== undefined) {
            this.trimmedSearchDisabledEmail = this.searchDisableEmpEmail.trim();
        }

        this.enable = true;
        this.disable = false;
        this.admin = false;
        this.postjob = false;
        ;
        this.showLoader = true;
        this.employeeRegList = [];
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2014",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "sort": {
                        "sortby": "createdtime",
                        "orderby": "DESC"
                    },
                    "records":{
                        "offset": (this.currentPage - 1) * this.itemsPerPage, 
                        "limit":this.itemsPerPage
                    },
                    "data": {
                        "companyName": this.trimmedSearchDisabledCompany,
                        // "companyDomain": this.searchDomain,
                        "email": this.trimmedSearchDisabledEmail,
                        "name": this.searchName,
                        "approved": -1,
                        // "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;
                    if (res.json.response.statuscode == 20021) {
                        this.showLoader = false;
                        this.showMessage = true;
                    }
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else if (res.json.response.statuscode == 0) {
                        this.showLoader = false;
                        this.totalItems = res.json.response.count;
                        this.employeeRegList = res.json.response.data;
                        this.showMessage = false;
                        this.searchApproveEmailSearch = ""
                        this.searchEmail = ""
                        this.searchCompany = ""
                        this.approveSearchCompany = ""
                    } else {
                        this.totalItems = res.json.response.count;
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    convertDate(date) {
        return new Date(date);
    };
    imageshowRec = "";
    getRFile(pid) {
        this.imageshowRec = "";
        ;
        // fileUpload.getCFile(pid).then(function (res) {
        //     this.imageshowRec = res;
        // });
    }

    id; employerDetails; employerId;
    email; name; website; companyName1; companyName; address1; photoId;
    viewJob(employerId) {
        ;

        this.imageshowRec = "";

        this.id = employerId;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2015",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": employerId
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    this.imageshowRec = "";
                    if (res.json.response.statusmessage == "Success") {
                        this.employerDetails = res.json.response.employerDetails;
                        // res.json.response.employerDetails.mobile.replace('.', '-');
                        for (var i = 0; this.employerDetails.length; i++) {
                            this.employerDetails[i].mobile = this.employerDetails[i].mobile.replace('.', '-');
                        }
                        this.imageshowRec = this.url + "/GroupzFS/getBlob?id=" + res.json.response.employerDetails.photoId;

                        this.display1 = "block";
                    } else if (res.json.response.statuscode == 50097) {
                        this.errorMessage(res.json.response.statusmessage);
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };

    arrayValuesSkill = [];
    arrayValuesSkill1 = [];
    arrayvalues2 = [];
    arrayvalues = [];
    exist; level;
    multipleskills(data, forMand) {
        this.arrayvalues2 = [];
        var mandatory = false;
        var prefferable = false;
        var manOrPre = "";
        if (forMand == "yes") {
            mandatory = true;
            prefferable = false;
            manOrPre = "Mandatory";
        } else {
            mandatory = false;
            prefferable = true;
            manOrPre = "Preferred";
        }
        this.exist = false;
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if ((escape(encodeURIComponent(data.skillName))) == this.arrayvalues[i].skillName) {
                this.exist = true;
            }
        }
        if (this.exist == true) {
            this.ngFlashMessageService.showFlashMessage({
                messages: ["This Skill is already added"],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
            });
        } else {
            if (this.arrayValuesSkill.length == 3) {
                this.ngFlashMessageService.showFlashMessage({
                    messages: ["Maximum skill limit reached"],
                    dismissible: true,
                    timeout: 2000,
                    type: 'danger'
                });
            } else {
                this.arrayvalues.push({
                    "skillName": (escape(encodeURIComponent(data.skillName))),
                    "level": this.level,
                    "mandatory": mandatory,
                    "preferable": prefferable,
                    "manOrPre": manOrPre
                });
                this.arrayValuesSkill.push({
                    "skillName": data.skillName,
                    "level": this.level,
                    "mandatory": mandatory,
                    "preferable": prefferable,
                    "manOrPre": manOrPre
                });
                for (var i = 0; i < this.arrayvalues.length; i++) {
                    if (this.arrayvalues[i].manOrPre == "Preferred") {
                        this.arrayvalues2.push({
                            "skillName": this.arrayvalues[i].skillName,
                            "level": this.arrayvalues[i].level,
                            "mandatory": false,
                            "preferable": true,
                        })
                    } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                        this.arrayvalues2.push({
                            "skillName": this.arrayvalues[i].skillName,
                            "level": this.arrayvalues[i].level,
                            "mandatory": true,
                            "preferable": false,
                        })
                    }
                }
            }
        }
        this.clearValues();
    };

    level1;
    multipleskills1(data, forMand) {
        this.arrayvalues2 = [];
        var mandatory = false;
        var prefferable = false;
        var manOrPre = "";
        if (forMand == "yes") {
            mandatory = true;
            prefferable = false;
            manOrPre = "Mandatory";
        } else {
            mandatory = false;
            prefferable = true;
            manOrPre = "Preferred";
        }
        this.exist = false;
        for (var i = 0; i < this.arrayvalues.length; i++) {
            if ((escape(encodeURIComponent(data))) == this.arrayvalues[i].skillName) {
                this.exist = true;
            }
        }
        if (this.exist == true) {
            this.ngFlashMessageService.showFlashMessage({
                messages: ["This Skill is already added"],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
            });
        } else {
            this.arrayvalues.push({
                "skillName": (escape(encodeURIComponent(data))),
                "level": this.level1,
                "mandatory": mandatory,
                "preferable": prefferable,
                "manOrPre": manOrPre
            });
            this.arrayValuesSkill1.push({
                "skillName": data,
                "level": this.level1,
                "mandatory": mandatory,
                "preferable": prefferable,
                "manOrPre": manOrPre
            });
            for (var i = 0; i < this.arrayvalues.length; i++) {
                if (this.arrayvalues[i].manOrPre == "Preferred") {
                    this.arrayvalues2.push({
                        "skillName": this.arrayvalues[i].skillName,
                        "level": this.arrayvalues[i].level,
                        "mandatory": false,
                        "preferable": true,
                    })
                } else if (this.arrayvalues[i].manOrPre != "Preferred") {
                    this.arrayvalues2.push({
                        "skillName": this.arrayvalues[i].skillName,
                        "level": this.arrayvalues[i].level,
                        "mandatory": true,
                        "preferable": false,
                    })
                }
            }
        }
        this.clearValues1();
    };

    skills1; showSkillTable1;
    clearValues1() {
        this.skills1 = "";
        if (this.arrayvalues.length > 0) {
            this.showSkillTable1 = true;
        }
    }

    skills; showSkillTable;
    clearValues() {
        ;
        this.skills = "";
        if (this.arrayvalues.length > 0) {
            this.showSkillTable = true;
        }
    }
    // end of view job
    //start delete

    assignId;
    deletePopup(employerId) {
        ;
        this.assignId = employerId.employerId;
        this.display2 = "block";
        //$('#deletepopup').modal('show');
    }

    deletejob() {
        ;
        // this.assignId = "";
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2016",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": [this.assignId],
                        /*  "adminId":$cookieStore.get('adminId'),*/
                        "disabled": true
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.listofEmpRegisters();
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Disabled successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'success'
                        });
                        this.display2 = "none";
                        //$('#deletepopup').modal('hide');
                    }
                });
    };

    enableEmployer(employerId) {
        this.id = employerId;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2016",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": [employerId],
                        /*  "adminId":$cookieStore.get('adminId'),*/
                        "disabled": false
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.json.response.statuscode == 0) {
                        this.listofEmpRegisters();
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Enabled successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'success'
                        });
                    }
                });
    };

    newentry;
    categoryList(item) {
        this.newentry = item.name;
        ;
        var categoryJson = {
            'json': {
                'request': {
                    'servicetype': '21',
                    'functiontype': '1014',
                    "industry": this.newentry,
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(categoryJson))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            // if (res.json.response.data) {
                            //     $.each(res.json.response.data, function (i, el) {
                            //         if ($.inArray(el, uniqueNames) === -1) {
                            //             uniqueNames.push(el);
                            //             finalNames.push({
                            //                 id: el,
                            //                 name: el
                            //             });
                            //         }
                            //     });
                            //     this.categories = finalNames;
                            // }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    }

    companies;
    listOfCompanies(data) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "6",
                    "functiontype": "6003"
                }
            }
        };

        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            /* this.skills = "";*/
                            var uniqueNames1 = [];
                            var finalNames1 = [];
                            if (res.json.response.data) {
                                /*$.each(res.json.response.data, function(i1, el1) {
                                     if (el1 !== "") {
                                     if ($.inArray(el1, uniqueNames1) === -1) {
                                             uniqueNames1.push(el1);
                                     finalNames1.push({name: el1 });
                                                                    }
                                                                }
                                                            });*/
                                this.companies = res.json.response.data;
                            }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    approveEmployer(employerId) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2033",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": [employerId],
                        /*"adminId":$cookieStore.get('adminId'),*/

                        "approved": true,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listofEmpRegisters();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Approved successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }

                });
    };

    manageSubadmin(employerId) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2024",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },

                    "data": {
                        "employerId": employerId,

                        "admin": true,
                        "adminId": localStorage.getItem('adminId')

                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == "0") {
                            this.listofApprovedEmpRegisters();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["success"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }

                });

    }


    manageEmployer(employerId) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2024",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },

                    "data": {
                        "employerId": employerId,

                        "admin": false,
                        "adminId": localStorage.getItem('adminId')

                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    ;

                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listofApprovedEmpRegisters();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["success"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }

                });
    }

    closeRecInfo() {
        ;
        this.imageshowRec = "";
        //$('#viewJobModal').modal('hide');
    }

    UnapproveEmployer(employerId) {
        ;
        var json = {
            "json": {
                "request": {
                    "servicetype": "2",
                    "functiontype": "2033",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": [employerId],
                        /*  "adminId":$cookieStore.get('adminId'),*/

                        "approved": false,
                        "offset": this.pagination
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.Success != undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            this.listofEmpRegisters();
                            this.ngFlashMessageService.showFlashMessage({
                                messages: ["Approved successfully"],
                                dismissible: true,
                                timeout: 2000,
                                type: 'success'
                            });
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    searchByCompany() {
        ;
        if (this.tab == 1) {
            this.listofEmpRegisters();
        } else if (this.tab == 2) {
            this.listofApprovedEmpRegisters();
        } else if (this.tab == 3) {
            this.listofDisabledEmpRegisters();
        }
    }

    noSkillError; noSkillErrorMsg;
    skillsforJob(item) {
        ;
        this.showSkillTable = false;
        this.arrayValuesSkill = [];
        this.arrayvalues = [];
        this.newentry = item.name;
        /*if(  this.newentry.length <  3){
          this.newentry = undefined;   
        }*/
        var json = {
            "json": {
                "request": {
                    "servicetype": "8",
                    "functiontype": "8007",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    /* "sort":{
                         "sortby":"title",
                         "orderby":"ASC"
                     },*/
                    "data": {
                        "domain": this.newentry
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        if (this.newentry != undefined) {
            this.http.get(this.url + '/JobzTop/Skill?request=' + temp)
                .subscribe(
                    (res: any) => {
                        if (res.success !== undefined) {
                            this.noSkillError = false;
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.message],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        } else {
                            if (res.json.response.statuscode == 0) {
                                /* this.skills = "";*/
                                var uniqueNames1 = [];
                                var finalNames1 = [];
                                if (res.json.response.data) {
                                    this.noSkillErrorMsg = "";
                                    this.noSkillError = false;
                                    /*$.each(res.json.response.data, function(i1, el1) {
                                         if (el1 !== "") {
                                         if ($.inArray(el1, uniqueNames1) === -1) {
                                                 uniqueNames1.push(el1);
                                         finalNames1.push({name: el1 });
                                                                        }
                                                                    }
                                                                });*/
                                    this.skills = res.json.response.data.skill;
                                }
                            } else if (res.json.response.statuscode == 20038) {
                                this.noSkillError = true;
                                this.noSkillErrorMsg = res.json.response.statusmessage;
                            } else {
                                this.noSkillError = false;
                                this.ngFlashMessageService.showFlashMessage({
                                    messages: [res.json.response.statusmessage],
                                    dismissible: true,
                                    timeout: 2000,
                                    type: 'danger'
                                });
                            }
                        }
                    });
        }
    };

    //end skills
    /*  this.skills1 = [];*/
    skillsforJob1(item) {
        ;
        if (item.name == undefined) {
            this.newentry = item;
        } else {
            this.newentry = item.name;
        }
        if (this.newentry.length < 3) {
            this.newentry = undefined;
        }
        var json = {
            "json": {
                "request": {
                    "servicetype": "8",
                    "functiontype": "8007",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    /* "sort":{
                         "sortby":"title",
                         "orderby":"ASC"
                     },*/
                    "data": {
                        "domain": this.newentry,
                        "interviewable": false
                    }
                }

            }
        };
        let temp = encodeURIComponent(JSON.stringify(json))

        if (this.newentry != undefined) {
            this.http.get(this.url + '/JobzTop/Skill?request=' + temp)
                .subscribe(
                    (res: any) => {
                        // UserService.skillList(json).then(function (res) {
                        if (res.success !== undefined) {
                            this.noSkillError = false;
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.message],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        } else {

                            if (res.json.response.statuscode == 0) {
                                /* this.skills = "";*/

                                var uniqueNames1 = [];
                                var finalNames1 = [];
                                if (res.json.response.data) {
                                    this.noSkillErrorMsg = "";

                                    this.noSkillError = false;

                                    /*$.each(res.json.response.data, function(i1, el1) {
                                         if (el1 !== "") {
                                         if ($.inArray(el1, uniqueNames1) === -1) {
                                                 uniqueNames1.push(el1);
                                         finalNames1.push({name: el1 });
                                                                        }
                                                                    }
                                                                });*/
                                    this.skills1 = res.json.response.data.skill;
                                }
                            } else if (res.json.response.statuscode == 20038) {
                                this.noSkillError = true;
                                this.noSkillErrorMsg = res.json.response.statusmessage;
                            } else {
                                this.noSkillError = false;
                                this.ngFlashMessageService.showFlashMessage({
                                    messages: [res.json.response.statusmessage],
                                    dismissible: true,
                                    timeout: 2000,
                                    type: 'danger'
                                });
                            }
                        }
                    });
        }
    };

    //start location
    countries = [];
    countryDisplay(data) {
        ;
        var countryJson = {
            "json": {
                "request": {
                    "servicetype": "100",
                    "functiontype": "101"
                    /* "country": "_ALL_"*/
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(countryJson))

        this.http.get(this.url + '/JobzTop/Geography?request=' + temp)
            .subscribe(
                (res: any) => {
                    // UserService.countriesList(countryJson).then(function (res) {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {
                                /*   $.each(res.json.response.data, function(i, el) {
                                    if (el !== "") {
                                        if ($.inArray(el, uniqueNames) === -1) {
                                            uniqueNames.push(el);
                                    for(var i=0;uniqueNames.length;i++){
                                    }		
                                        }
                                    }
                                });*/
                                var country = res.json.response.data;
                                for (var i = 0; i < country.length; i++) {
                                    this.countries.push(country[i].countryname);
                                }
                            }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });


    };

    states = [];
    stateDisplay(item) {
        ;
        var countryJson = {
            "json": {
                "request": {

                    "servicetype": "100",
                    "functiontype": "102",
                    /* "country": "_ALL_"*/

                    "data": {
                        "countryname": item
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(countryJson))

        this.http.get(this.url + '/JobzTop/Geography?request=' + temp)
            .subscribe(
                (res: any) => {
                    // UserService.countriesList(countryJson).then(function (res) {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {
                                /*   $.each(res.json.response.data, function(i, el) {
                                    if (el !== "") {
                                        if ($.inArray(el, uniqueNames) === -1) {
                                            uniqueNames.push(el);
                                    for(var i=0;uniqueNames.length;i++){
                                    }		
                                        }
                                    }
                                });*/
                                var state = res.json.response.data;
                                this.states = [];
                                for (var i = 0; i < state.length; i++) {
                                    this.states.push(state[i].statename);
                                }
                            }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    cities = [];
    cityDisplay(country, state) {
        ;
        var cityJson = {
            "json": {
                "request": {
                    "servicetype": "100",
                    "functiontype": "103",
                    "data": {
                        "countryname": country,
                        "statename": state
                    }
                }
            }
        };
        let temp = encodeURIComponent(JSON.stringify(cityJson))

        this.http.get(this.url + '/JobzTop/Geography?request=' + temp)
            .subscribe(
                (res: any) => {
                    if (res.success !== undefined) {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.message],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    } else {
                        if (res.json.response.statuscode == 0) {
                            var uniqueNames = [];
                            var finalNames = [];
                            if (res.json.response.data) {
                                /*   $.each(res.json.response.data, function(i, el) {
                                    if (el !== "") {
                                        if ($.inArray(el, uniqueNames) === -1) {
                                            uniqueNames.push(el);
                                    for(var i=0;uniqueNames.length;i++){
                                    }		
                                        }
                                    }
                                });*/

                                var city = res.json.response.data;
                                this.cities = [];
                                for (var i = 0; i < city.length; i++) {
                                    this.cities.push(city[i].cityname);
                                }

                            }
                        } else {
                            this.ngFlashMessageService.showFlashMessage({
                                messages: [res.json.response.statusmessage],
                                dismissible: true,
                                timeout: 2000,
                                type: 'danger'
                            });
                        }
                    }
                });
    };

    whoareyou = undefined;
    companyDomain;
    callpostJob(employerId, companyDomain) {
        ;
        /* this.showListJobs = false;
    this.showPostJobs = true;*/
        this.router.navigate(['/postJob']);
        this.employerId = employerId;
        localStorage.setItem('employerId', employerId);
        this.companyDomain = companyDomain;
        window.scrollTo(500, 0);

    };

    levelsofarray; Level; jobTitle; jobDescription; numberOfPositions;
    country; state; city; experienceLevel; assessmentReq; qualification; newIndustry; newCategory;
    saveValues() {
        ;
        this.employerId = localStorage.getItem('employerId');
        this.levelsofarray.push({
            "skillName": this.skills,
            "level": this.Level
        });
        var json = {
            "json": {
                "request": {
                    "servicetype": "7",
                    "functiontype": "7000",
                    "session": {
                        "sessionid": localStorage.getItem('sessionId')
                    },
                    "data": {
                        "employerId": this.employerId,
                        "companyDomain": this.companyDomain,

                        "jobTitle": encodeURIComponent(escape(this.jobTitle)),
                        "jobDescription": "<p>" + encodeURIComponent(escape(this.jobDescription)) + "</p>",
                        "numberOfPositions": this.numberOfPositions,
                        "location": {
                            "country": this.country,
                            "state": this.state,
                            "city": this.city
                        },
                        "experienceLevel": this.experienceLevel,
                        "assessment": this.assessmentReq,
                        "qualification": this.qualification,
                        "industry": this.newIndustry.name,
                        "category": this.newCategory.name,
                        "keySkills": this.arrayvalues
                    }
                }
            }
        }
        let temp = encodeURIComponent(JSON.stringify(json))

        this.http.get(this.url + '/JobzTop/AdminJobs?request=' + temp)
            .subscribe(
                (res: any) => {
                    // UserService.admineEmpJob(json).then(function (res) {

                    if (res.json.response.statusmessage == "Success") {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: ["Posted successfully"],
                            dismissible: true,
                            timeout: 2000,
                            type: 'success'
                        });
                    } else {
                        this.ngFlashMessageService.showFlashMessage({
                            messages: [res.json.response.statusmessage],
                            dismissible: true,
                            timeout: 2000,
                            type: 'danger'
                        });
                    }
                });
    };
    decodeString(str) {
        if (str != undefined) {
            return (unescape(decodeURIComponent(str)));
        }
    }
    closePop() {

        this.display1 = "none";
        this.display2 = "none";

    }


}
function paginationChanged(event: Event, any: any) {
    throw new Error('Function not implemented.');
}

