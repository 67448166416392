import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
  providers: [DatePipe]
})
export class CompaniesComponent implements OnInit {

  showLoader = false;
  display3 = 'none';
  planeIdChecking = "";
  setPlansId = "";
  listComp = [];
  planList = [];
  companieslist = [];
  showBeforePlan = true;
  showAfterPlan = false;
  tab =1;
  url = localStorage.getItem("url");
  display2 = 'none';
  id;
  data: any;
  errorMessage;
  display1 = 'none';
  archiveCalls;
  companyName: string;
  jobsValidity;
  creditsposition;
  creditsview;
  max;
  min;
  industry;
  domain;
  totalcredits;
  creditsperjob;
  creditsperassessment;
  intervalallowed;
  slabtime;
  slabinterval;
  creditsPerSlab;
  plan;
  searchCompanyName;
  searchDomain;
  searchCredits;
  reason;
  invalidSession;
  companiesList = [];
  count: number = 1;
  assignId;
  companyId;
  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService) { }

  ngOnInit() {
    this.listingCompanies();
    this.listingPlans();
  }

  currentPage: number = 1;
  currentTab: number =1;
  itemsPerPage:number=10;
  totalItems:number=0;
  search() {
    this.currentPage = 1;
    this.totalItems = 0;
    this.listingCompanies()
  }

  trimmedSearchCompanyName;
  trimmedSearchDomain;
  trimmedSearchCredits;

  comPagination(tab){
    if(tab == 1){
      this.listingCompanies()
  }else{
      this.listingDisableCompanies()
  }
  }
  listingCompanies() {
    this.tab = 1;
    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }
    if (this.searchCompanyName !== undefined) {
      this.trimmedSearchCompanyName = this.searchCompanyName.trim();
    }

    if (this.searchDomain !== undefined) {
      this.trimmedSearchDomain = this.searchDomain.trim();
    }

    if (this.searchCredits !== undefined) {
      this.trimmedSearchCredits = this.searchCredits.trim();
    }

    this.showLoader = true;

    this.companieslist = [];
    this.listComp = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2049",
          "sort": {
            "sortby": "createdtime",
            "orderby": "DESC"

          },
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "companyName": this.trimmedSearchCompanyName,
            "domain": this.trimmedSearchDomain,
            "totalcredits": this.trimmedSearchCredits
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {

            this.searchDisableCompanyName = "";
            this.searchDisableDomain = "";
            // this.searchDisableCredits = "";

            this.showLoader = false;
            this.companiesList = res.json.response.data.companyList;
            this.totalItems = res.json.response.data.count;
            this.count = res.json.response.data.count;
            for (var i = 0; i < this.companiesList.length; i++) {
              this.listComp.push(this.companiesList[i]);
            }
          } else if (res.json.response.statuscode == 50097) {
            this.invalidSession = res.json.response.statusmessage;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  searchDisableCompanyName;
  searchDisableDomain;
  searchDisableCredits;
  trimmedSearchDisableCompanyName;
  trimmedSearchDisableDomain;
  trimmedSearchDisableCredits;
  listingDisableCompanies() {
    this.tab = 2;

    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }
    if (this.searchDisableCompanyName !== undefined) {
      this.trimmedSearchDisableCompanyName = this.searchDisableCompanyName.trim();
    }

    if (this.searchDisableDomain !== undefined) {
      this.trimmedSearchDisableDomain = this.searchDisableDomain.trim();
    }

    if (this.searchDisableCredits !== undefined) {
      this.trimmedSearchDisableCredits = this.searchDisableCredits.trim();
    }

    this.showLoader = true;

    this.companieslist = [];
    this.listComp = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2049",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "disabled": true,
            "companyName": this.trimmedSearchDisableCompanyName,
            "domain": this.trimmedSearchDisableDomain,
            "totalcredits": this.trimmedSearchDisableCredits
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });

          } else if (res.json.response.statuscode == 0) {
            this.totalItems = res.json.response.data.count;
            this.searchCompanyName = "";
            this.searchDomain = "";
            // this.searchCredits = "";

            this.showLoader = false;
            this.companiesList = res.json.response.data.companyList;
            for (var i = 0; i < this.companiesList.length; i++) {
              this.listComp.push(this.companiesList[i]);
            }
          } else if (res.json.response.statuscode == 50097) {
            this.invalidSession(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };
  decode(str: string) {
    if (str != undefined) {
      return unescape(str.replace(/[+]/g, "%20"))
    }
  }
  convertDate(date) {
    return new Date(date);
  };
  searchByCompany() {

    if (this.tab == 1) {
      this.listingCompanies();

    } else if (this.tab == 2) {
      this.listingDisableCompanies();
    }
  }

  deletePopup(companyId) {

    this.assignId = companyId;
    this.display2 = "block";
    //$('#deletepopup').modal('show');
  }
  deletejob() {


    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2051",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.assignId.companyId,
            "reason": this.reason,
            /*  "adminId":$cookieStore.get('adminId'),*/
            "disabled": true
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            this.listingCompanies();
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Disabled successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            this.display2 = "none";
            //$('#deletepopup').modal('hide');
          }
        });
  };

  enable(item) {



    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2051",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": item,

            "disabled": false
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            this.listingDisableCompanies();
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Enabled successfully"],
              dismissible: true,
              timeout: 2000,
              type: 'success'
            });
            this.display2 = "none";
            //$('#deletepopup').modal('hide');
          }
        });
  };
  closePop() {
    this.display2 = "none";
    this.display1 = "none";
    this.display3 = "none";
  }

  changePlan() {
    this.showBeforePlan = false;
    this.showAfterPlan = true;
  }

  setPlanId(item) {

    this.setPlansId = item.id;
    window.localStorage.setItem('planId', item);

  }
  viewCompany(item) {

    if (item.companyId == undefined) {
      this.id = item;

    } else {
      this.id = item.companyId;

    }
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2050",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.id
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statusmessage == "Success") {
            this.display3 = "block";
            this.data = res.json.response.data;


            // if(this.data.archiveCalls == true){
            //   this.archiveCalls = "true";
            //  }else{
            //   this.archiveCalls = "false";
            //  }
            this.companyName = unescape(decodeURIComponent(res.json.response.data.companyName));
            this.jobsValidity = res.json.response.data.jobsValidity;
            this.creditsposition = res.json.response.data.credits_per_position;

            this.creditsview = res.json.response.data.credits_per_view;
            this.max = res.json.response.data.maximumTime;
            this.min = res.json.response.data.minimumTime;
            this.industry = res.json.response.data.industry;
            this.domain = res.json.response.data.domain;
            this.totalcredits = res.json.response.data.totalcredits;
            this.creditsperjob = res.json.response.data.credits_per_job;
            this.creditsperassessment = res.json.response.data.creditsPerAssessment;
            this.intervalallowed = res.json.response.data.intervalAllowed;
            this.slabtime = res.json.response.data.slabTime;
            this.slabinterval = res.json.response.data.slabInterval;
            this.creditsPerSlab = res.json.response.data.creditsPerSlab;

            this.plan = res.json.response.data.plan;

          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  editCompany(item) {


    if (item.companyId == undefined) {
      this.id = item;

    } else {
      this.id = item.companyId;

    }
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2050",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.id
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statusmessage == "Success") {
            this.display1 = "block";
            this.data = res.json.response.data;


            // if(this.data.archiveCalls == true){
            //   this.archiveCalls = "true";
            //  }else{
            //   this.archiveCalls = "false";
            //  }
            this.companyName = unescape(decodeURIComponent(res.json.response.data.companyName));
            this.jobsValidity = res.json.response.data.jobsValidity;
            this.creditsposition = res.json.response.data.credits_per_position;

            this.creditsview = res.json.response.data.credits_per_view;
            this.max = res.json.response.data.maximumTime;
            this.min = res.json.response.data.minimumTime;
            this.industry = res.json.response.data.industry;
            this.domain = res.json.response.data.domain;
            this.totalcredits = res.json.response.data.totalcredits;
            this.creditsperjob = res.json.response.data.credits_per_job;
            this.creditsperassessment = res.json.response.data.creditsPerAssessment;
            this.intervalallowed = res.json.response.data.intervalAllowed;
            this.slabtime = res.json.response.data.slabTime;
            this.slabinterval = res.json.response.data.slabInterval;
            this.creditsPerSlab = res.json.response.data.creditsPerSlab;
            this.plan = res.json.response.data.plan;
            this.archiveCalls = res.json.response.data.archiveCalls;

          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  updateDetails() {
    if (this.archiveCalls == "true") {
      this.archiveCalls = true;
    } else {
      this.archiveCalls = false;
    }
    this.planeIdChecking = window.localStorage.getItem('planId');
    if (this.planeIdChecking == "") {
      this.ngFlashMessageService.showFlashMessage({
        messages: ["Please select Questionnaire plan"],
        dismissible: true,
        timeout: 2000,
        type: 'danger'
      });

    }
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2051",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.id,

            "companyName": (escape(encodeURIComponent(this.companyName))),
            "jobsValidity": this.jobsValidity,

            "credits_per_position": this.creditsposition,
            "credits_per_view": this.creditsview,
            "maximumTime": this.max,
            "minimumTime": this.min,
            "industry": this.industry,
            "credits_per_job": this.creditsperjob,
            "creditsPerAssessment": this.creditsperassessment,
            "intervalAllowed": this.intervalallowed,
            "slabTime": this.slabtime,
            "slabInterval": this.slabinterval,
            "creditsPerSlab": this.creditsPerSlab,
            "planId": this.planeIdChecking,
            "archiveCalls": this.archiveCalls
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statusmessage == "Success") {
            this.display1 = "none";
            if (this.tab == 1) {
              this.listingCompanies();
            } else if (this.tab == 2) {
              this.listingDisableCompanies();
            }
            this.showBeforePlan = true;
            this.showAfterPlan = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });

          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };
  listingPlans() {
    this.planList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2058",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": false
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {

          if (res.json.response.statusmessage == "Success") {

            this.planList = res.json.response.data.list;
          } else if (res.json.response.statuscode == 50097) {
            this.errorMessage(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

}
