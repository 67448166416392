import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { DatePipe } from '@angular/common';
import { UserService } from '../user.service';

@Component({
  selector: 'app-interviews',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.css'],
  providers: [DatePipe]
})
export class InterviewsComponent implements OnInit {

  showAllJobs = true;
  showPostJob = true;
  url = localStorage.getItem("url");
  tab = 1;

  constructor(private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private datePipe: DatePipe, private user: UserService) { }

  ngOnInit() {
    this.listInterviews();
    //this.skillsforJobs();
  }
  showLoader;
  interviewsList = [];
  dob; joiningdate; searchCandName; searchAssessorName; searchSkill; pagination
  currentPage:number=1;
  currentTab:number=1;
  itemsPerPage:number=10;
  totalItems:number=0;

  interPagination(tab){
    if(tab == 1){
      this.listInterviews()
  }else if(tab == 2){
      this.onGoingInterviews()
  }else{
      this.listDisableInterviews()
  }
  }
  listInterviews() {
    this.tab = 1
    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }

    this.showLoader = true;
    ;
    this.interviewsList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            "startDate": this.dob,
            "endDate": this.joiningdate,
            "candidateName": this.searchCandName,
            "assessorName": this.searchAssessorName,
            "skill": this.searchSkill,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.showLoader = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.interviewsList = res.json.response.data.interviews;
            this.totalItems = res.json.response.data.count
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };
  listingSponsoredAssessorsApprove() {
    this.showLoader = true;
    ;
    this.interviewsList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "data": {
            "sponsored": true,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.showLoader = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.interviewsList = res.json.response.data.interviews;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  assessorList = [];
  listingFreelanceAssessorsApprove() {
    this.showLoader = true;
    ;
    var skill = "";
    if (this.searchSkill != undefined) {
      skill = this.searchSkill;
      skill = encodeURIComponent(skill);
    } else {
      skill = undefined;
    }
    this.assessorList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "data": {
            "sponsored": false,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statusmessage == "No match found") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.assessorList = res.json.response.data.assessorList;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  listingSponsoredAssessorsDisable() {
    this.showLoader = true;
    ;
    var skill = "";
    if (this.searchSkill != undefined) {
      skill = this.searchSkill;
      skill = encodeURIComponent(skill);
    } else {
      skill = undefined;
    }
    this.assessorList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "data": {
            "sponsored": true,
            "disabled": 1,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statusmessage == "No match found") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.assessorList = res.json.response.data.assessorList;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  listingFreelanceAssessorsDisable() {
    this.showLoader = true;
    ;
    var skill = "";
    if (this.searchSkill != undefined) {
      skill = this.searchSkill;
      skill = encodeURIComponent(skill);
    } else {
      skill = undefined;
    }
    this.assessorList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "data": {
            "sponsored": false,
            "disabled": 1,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statusmessage == "No match found") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.assessorList = res.json.response.data.assessorList;
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }

        });
  };

  onGoingInterviews() {
    this.tab = 2

    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }

    this.showLoader = true;
    ;
    this.interviewsList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            /*"adminId": $cookieStore.get('adminId'),*/
            /* "approved":1,*/
            "startDate": this.dob,
            "endDate": this.joiningdate,
            "candidateName": this.searchCandName,
            "assessorName": this.searchAssessorName,

            "skill": this.searchSkill,
            "hangUp": false,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.showLoader = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.showLoader = false;
            this.interviewsList = res.json.response.data.interviews;
            this.totalItems = res.json.response.data.count
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  skills; skillId;
  multipleskills = function (item) {
    ;
    for (var i = 0; i < this.skills.length; i++) {
      if (item == this.skills[i].name.skillName) {
        this.skillId = this.skills[i].name.skillId;
      }
    }
  }

  searchInterviews() {

    ;
    this.interviewsList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "adminId": localStorage.get('adminId'),
            "skillId": this.skillId,
            "startDate": this.dob,
            "endDate": this.joiningdate,

            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.interviewsList = res.json.response.data.interviews;
            this.dob = "";
            this.joiningdate = "";
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  listDisableInterviews() {
    ;
    this.tab = 3
    if (this.tab !== this.currentTab) {
      this.currentPage = 1;
      this.currentTab = this.tab;
  }

    this.showLoader = true;
    this.interviewsList = [];
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2035",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "sort": {
            "sortby": "createdDate",
            "orderby": "DESC"
          },
          "records": {
            "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
            "limit": this.itemsPerPage
          },
          "data": {
            /*"adminId": $cookieStore.get('adminId'),
             */
            "startDate": this.dob,
            "endDate": this.joiningdate,
            "candidateName": this.searchCandName,
            "assessorName": this.searchAssessorName,
            "skill": this.searchSkill,
            "disabled": 1,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.showLoader = false;
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });

          } else if (res.json.response.statuscode == "0") {
            this.showLoader = false;
            this.interviewsList = res.json.response.data.interviews;
            this.totalItems = res.json.response.data.count
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }

        });
  };

  multipleids;
  approveInterviews = function (item) {
    ;

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2036",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /* "adminId": $cookieStore.get('adminId'),*/
            "approve": 1,
            "interviewId": [item.interviewId],
            "skill": this.multipleids,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });

          } else {
            if (res.json.response.statuscode == 0) {
              /*$scope.listingAssessors("false");*/
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Approved successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'Success'
              });
              this.listInterviews();
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  unapproveInterviews(item) {
    ;

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2036",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /*"adminId": $cookieStore.get('adminId'),*/
            "approved": 0,
            "interviewId": [item.interviewId],
            "skill": this.multipleids,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == "0") {
              /*$scope.listingAssessors("false");*/
              this.ngFlashMessageService.showFlashMessage({
                messages: ["UnApproved successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.listInterviews();
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  assignId = "";
  displayDisable;
  deletePopup(interviewId) {
    ;
    this.assignId = interviewId.interviewId;
    //$('#deletepopup').modal('show');
    this.displayDisable = 'block';
    this.reasonForDisable = ""
  }

  OnCloseMod() {
    this.displayDisable = 'none';
  }

  approve; reasonForDisable; interviewId;
  disableInterviews() {
    this.assignId
    ;

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2036",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /* "adminId": $cookieStore.get('adminId'),*/
            "disable": 1,
            "interviewId": [this.assignId],
            "skill": this.multipleids,
            "reason": this.reasonForDisable,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              /*$scope.listingAssessors("false");*/
              if (this.approve == 0) {
                //this.listingAssessors();
              } else if (this.approve == 1) {
                this.listDisableInterviews();
              }
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Disabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'Success'
              });
              this.displayDisable = 'none';
              //$('#deletepopup').modal('hide');
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  enableInterview = function (item) {
    ;

    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2036",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /* "adminId": $cookieStore.get('adminId'),*/
            "disable": -1,
            "interviewId": [item.interviewId],
            "skill": this.multipleids,
            "offset": this.pagination
          }
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              /*$scope.listingAssessors("false");*/
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Enabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'Success'
              });
              this.listDisableInterviews();
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  skillsforJobs() {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "6",
          "functiontype": "6001",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
        }
      }
    };
    let temp = encodeURIComponent(JSON.stringify(json))

    this.http.get(this.url + '/JobzTop/Admin?request=' + temp)
      .subscribe(
        (res: any) => {
          ;
          if (res.success !== undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.message, true],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {

              var uniqueNames1 = [];
              var finalNames1 = [];
              if (res.json.response.data) {
                // $.each(res.json.response.data, function (i1, el1) {
                //     if (el1 !== "") {
                //         if ($.inArray(el1, uniqueNames1) === -1) {
                //             uniqueNames1.push(el1);
                //             finalNames1.push({
                //                 name: el1
                //             });
                //         }
                //     }
                // });
                this.skills = finalNames1;
              }
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

}
