import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgFlashMessageService } from 'ng-flash-messages';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { UserService } from '../user.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {
  oFrom: FormGroup;
  post: any;
  url = localStorage.getItem("url");
  constructor(private fb: FormBuilder, private http: HttpClient, private ngFlashMessageService: NgFlashMessageService, private user: UserService) {
    this.oFrom = fb.group({
      'domain': [null, Validators.compose([Validators.required, Validators.pattern(/[a-zA-Z]/)])]
    });
  }

  ngOnInit() {
    this.listingDomains();
  }

  // filteredData: any[] = [];
  // searchText: string = '';
  search() {
    // this.filteredData = this.domainsList.filter(item => {
    //   return item.domain.toLowerCase().includes(this.searchDomainName.toLowerCase());
    // });
    this.currentPage = 1; // Reset to the first page
    this.totalItems = 0;
    this.listingDomains()
    // this.onChangeListingDomain()
  }

  searchEnableDomainName;
  domainsList = [];
  showLoader;
  searchDisabled;
  searchDomainName;
  searchDisabledBy;
  json;
  trimmed;
  trim;
  dataLoaded;
  trimmedDomianName;
  currentPage:number=1;
  itemsPerPage:number=10;
  totalItems:number=0;

  listingDomains() {
    this.showLoader = true;
    ;
    this.domainsList = [];
    if (this.searchDisabled == '') {
      this.trimmedDomianName = this.searchDomainName.trim()
      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2054",
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "records": {
              "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
              "limit": this.itemsPerPage
            },
            "data": {
              "domain": this.trimmedDomianName,
              "disabledBy": this.searchDisabledBy,
            }
          }
        }
      };
    } else {
      this.trimmed = this.searchEnableDomainName
      if (this.trimmed !== undefined) {
        this.trim = this.trimmed.trim();
      }
      this.json = {
        "json": {
          "request": {
            "servicetype": "2",
            "functiontype": "2054",
            "session": {
              "sessionid": localStorage.getItem('sessionId')
            },
            "records": {
              "offset": (this.currentPage - 1) * this.itemsPerPage, // Calculate the offset
              "limit": this.itemsPerPage
            },
            "data": {
              "domain": this.trim,
              "disabledBy": this.searchDisabledBy,
              "disabled": this.searchDisabled
            }
          }
        }
      };
    }
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(this.json)))
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20021") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0){
            this.searchDisableDomainName = "";
            if (this.searchDisabled == '') {
              this.dataLoaded = 1;
              this.searchEnableDomainName = "";
              this.searchDisableDomainName = "";
              this.totalItems = res.json.response.data.count;1;
            } else {
              this.dataLoaded = 2;
              this.searchDomainName = "";
              this.searchDisableDomainName = "";
              this.totalItems = res.json.response.data.count;
            }
            if (res.json.response.data.domainList.length > 0) {
              this.showLoader = false;
              this.domainsList = res.json.response.data.domainList;
            }
          } else if (res.json.response.statuscode == 50097) {
            this.invalidSession(res.json.response.statusmessage);
          } else {
            this.totalItems =0
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };
  onChangeListingDomain(){
    this.currentPage = 1;
    this.listingDomains()
  }

  searchDisableDomainName;
  trimmedDisable
  listingDisabledDomains() {
    this.showLoader = true;
    ;
    this.searchDisableDomainName;
    this.domainsList = [];
    this.trimmedDisable = this.searchDisableDomainName.trim();
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2054",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": true,
            "domain": this.trimmedDisable,
            "disabledBy": this.searchDisabledBy
          }
        }
      }
    };
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          ;
          if (res.json.response.statuscode == "20051") {
            this.showLoader = false;
          }
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else if (res.json.response.statuscode == 0) {
            this.searchEnableDomainName = "";
            this.searchDomainName = "";
            this.dataLoaded = 3;
            this.showLoader = false;
            this.domainsList = res.json.response.data.domainList;
            this.totalItems = res.json.response.data.count
          } else if (res.json.response.statuscode == 50097) {
            this.invalidSession(res.json.response.statusmessage);
          } else {
            this.totalItems =0
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          }
        });
  };

  assignId = "";
  displayDisable;
  deletePopup(domainId) {
    ;
    this.assignId = domainId.id;
    this.reason = ""
    this.displayDisable = 'block';
  }

  OnCloseMod() {
    this.displayDisable = 'none';
    this.oFrom.reset();
  }

  reason;
  pagination;
  invalidSession;
  disableDomain() {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2055",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "disabled": true,
            "id": this.assignId,
            "reason": this.reason,
          }
        }
      }
    };
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.listingDomains();
              // $('#disableModal').modal('hide');
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Disabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.displayDisable = 'none';
              this.listingDomains();
            } else if (res.json.response.statuscode == 20021) {
              this.invalidSession(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  id;
  enableDomain = function (id) {
    ;
    this.id = id;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2055",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            /*"adminId": $cookieStore.get('adminId'),*/
            "disabled": false,
            "id": id,
            "offset": this.pagination
          }
        }
      }
    };
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {

          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Enabled successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.listingDomains();
            } else if (res.json.response.statuscode == 20021) {
              this.invalidSession(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  };

  convertDate(date) {
    return new Date(date);
  };

  approve;
  commonSearch() {
    if (this.approve == 0) {
      this.listingDomains();
    } else if (this.approve == 1) {
      this.listingDisabledDomains();
    }
  };

  display;
  addPopup() {
    this.domain = ""
    this.display = 'block';
  }
  OnCloseHandled() {
    this.display = 'none';
    this.oFrom.reset();
  }

  domain;
  addDomain() {
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2053",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "domain": this.domain
          }
        }
      }
    }
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          ;
          if (res.Success != undefined) {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
          } else {
            if (res.json.response.statuscode == 0) {
              /* this.listingDisabledDomains();*/
              this.ngFlashMessageService.showFlashMessage({
                messages: ["Added successfully"],
                dismissible: true,
                timeout: 2000,
                type: 'success'
              });
              this.listingDomains();
              this.display = 'none';
            } else if (res.json.response.statuscode == 20021) {
              this.invalidSession(res.json.response.statusmessage);
            } else {
              this.ngFlashMessageService.showFlashMessage({
                messages: [res.json.response.statusmessage],
                dismissible: true,
                timeout: 2000,
                type: 'danger'
              });
            }
          }
        });
  }

  domainId = "";
  domainNew;
  displayEdit;
  editDomain(item) {
    this.domainNew = item.domain;
    this.domainId = item.id;
    this.displayEdit = 'block';
  }
  OnClose() {
    this.displayEdit = 'none';
    this.oFrom.reset();
  }

  update = function () {
    ;
    var json = {
      "json": {
        "request": {
          "servicetype": "2",
          "functiontype": "2055",
          "session": {
            "sessionid": localStorage.getItem('sessionId')
          },
          "data": {
            "id": this.domainId,
            "domain": this.domainNew
          }
        }
      }
    };
    this.http.get(this.url + '/JobzTop/Admin?request=' + encodeURIComponent(JSON.stringify(json)))
      .subscribe(
        (res: any) => {
          if (res.json.response.statuscode == 0) {
            this.ngFlashMessageService.showFlashMessage({
              messages: ["Updated Successfully!!"],
              dismissible: true,
              timeout: 5000,
              type: 'success'
            });
            this.listingDomains();
            this.displayEdit = 'none';
          } else if (res.json.response.statuscode == 50097) {
            this.invalidSession(res.json.response.statusmessage);
          } else {
            this.ngFlashMessageService.showFlashMessage({
              messages: [res.json.response.statusmessage],
              dismissible: true,
              timeout: 2000,
              type: 'danger'
            });
            //$('#editJobModal').scrollTop(0);
          }
        });
  }

}
